import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Divider,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Link,
  Grid,
  Popper,
  Grow,
  Container,
  Hidden,
  IconButton,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
// import ClickAwayListener from '@mui/base/ClickAwayListener';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { DefaultPlayer as Video } from 'react-html5video';
// import courseCreation from '../../website/images/courseCreate.png';
import LoginForm from './LoginModal';
import Logo from '../../assets/logo/logo.png';
import palette from '../../theme/palette';
import ThemeProvider from '../../webTheme';
import menuItems from './MenuItems';
import DefaultNavbarDropdown from './DefaultNavbarDropdown';
import Iconify from '../../components/Iconify';
import breakpoints from '../../theme/breakpoints';
import BacKToTop from '../../website/components/BackToTop';
import MobileNavbar from './DefaultNavbarMobile';
import AccountPopover from '../dashboard/AccountPopover';
import VideoModal from '../../components/modal/VideoModal';
import settings from '../../settings';
import adminServices from '../../services/adminServices';

import 'react-circular-progressbar/dist/styles.css';
import 'react-html5video/dist/styles.css';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 68;
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  //   boxShadow: 'none',
  background: 'rgb(253 253 253 / 71%);',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  boxShadow: '0px -30px 28px -3px #686464',
  borderBottom: '1px solid #F2F4F7',
  color: '#000',
  //   backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    //     width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    width: '100%',
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  padding: theme.spacing(0),
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0),
  },
}));

const MainRootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  width: '100%',
  overflow: 'auto',
  minHeight: '100%',
  background: '#FAFAFA',
}));

// ----------------------------------------------------------------------

export default function WebsiteLayout({ onOpenSidebar }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation(); 
  const location = useLocation();
  const { pathname } = useLocation();
  const isLogin = useSelector((state) => state.isLogin);
  const userRole = useSelector((state) => (state.userInfo ? state.userInfo.role : null));
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const openCourseMenu = Boolean(anchorEl2);
  const test = true;
  const [dropdown, setDropdown] = useState('');
  const [dropdownEl, setDropdownEl] = useState('');
  const [dropdownName, setDropdownName] = useState('');
  const [arrowRef, setArrowRef] = useState(null);

  const [nestedDropdown, setNestedDropdown] = useState('');
  const [nestedDropdownEl, setNestedDropdownEl] = useState('');
  const [nestedDropdownName, setNestedDropdownName] = useState('');

  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [openContent, setOpenContent] = React.useState(false);

  const openMobileNavbar = () => setMobileNavbar(!mobileNavbar);

  useEffect(() => {
    // ScrollTop
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener('resize', displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', displayMobileNavbar);
  }, [location]);

  useEffect(() => {
    if (isLogin && (pathname === '/sign-up' || pathname==='/login')) {
      if (isLogin && (userRole === 'SUPER_ADMIN' || userRole === 'CLIENT_ADMIN')) {
        navigate('/app/dashboard');
      } else if (isLogin && (userRole === 'CONTENT_WRITER' || userRole === 'AUTH_USER')) {
        navigate('/app/course');
      } else {
        navigate('/auth/my-courses');
      }
    }
  }, [pathname]);
  const renderNavbarItems = menuItems(isLogin, userRole).map(({ name, icon, href, route, collapse }) => (
    <DefaultNavbarDropdown
      key={name}
      name={name}
      icon={icon}
      href={href}
      route={route}
      collapse={Boolean(collapse)}
      onMouseEnter={({ currentTarget }) => {
        if (collapse) {
          setDropdown(currentTarget);
          setDropdownEl(currentTarget);
          setDropdownName(name);
        } else {
          setDropdown(null);
        }
      }}
      //   onMouseLeave={() => collapse && setDropdown(null)}
    />
  ));

  const renderRoutes = menuItems(isLogin, userRole).map(({ name, listStyle, collapse, columns, rowsPerColumn }) => {
    let template;
    // Render the dropdown menu that should be display as columns
    if (collapse && columns && name === dropdownName) {
      const calculateColumns = collapse.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / rowsPerColumn);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);

      template = (
        <Grid key={name} container spacing={2} py={1} px={1.5}>
          {calculateColumns.map((cols, key) => {
            const gridKey = `grid-${key}`;
            const dividerKey = `divider-${key}`;

            return (
              <Grid key={gridKey} item xs={12 / columns} sx={{ position: 'relative' }}>
                {cols.map((col, index) => (
                  <Fragment key={col.name}>
                    <Typography
                      display="block"
                      variant="button"
                      fontWeight="bold"
                      textTransform="capitalize"
                      py={1}
                      px={0.5}
                      //   mt={index !== 0 ? 2 : 0}
                    >
                      {col.name}
                    </Typography>
                    {col.collapse.map((item) => (
                      <Typography
                        key={item.name}
                        component={item.route ? Link : Link}
                        to={item.route ? item.route : ''}
                        href={item.href ? item.href : (e) => e.preventDefault()}
                        target={item.href ? '_blank' : ''}
                        rel={item.href ? 'noreferrer' : 'noreferrer'}
                        minWidth="11.25rem"
                        display="block"
                        variant="button"
                        color="text"
                        textTransform="capitalize"
                        fontWeight="regular"
                        py={0.625}
                        px={2}
                        sx={({ palette: { grey, dark } }) => ({
                          borderRadius: 'md',
                          cursor: 'pointer',
                          transition: 'all 300ms linear',

                          '&:hover': {
                            backgroundColor: grey[200],
                            color: '#fafafa',
                          },
                        })}
                      >
                        {item.name}
                      </Typography>
                    ))}
                  </Fragment>
                ))}
                {key !== 0 && (
                  <Divider
                    key={dividerKey}
                    orientation="vertical"
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '-4px',
                      transform: 'translateY(-45%)',
                      height: '90%',
                    }}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      );

      // Render the dropdown menu that should be display as list items
    } else if (collapse && name === dropdownName && listStyle === 'list') {
      template = collapse.map((item) => {
        const linkComponent = {
          component: Link,
          href: item.href,
          target: '_blank',
          rel: 'noreferrer',
        };

        const routeComponent = {
          component: RouterLink,
          to: item.route,
        };

        return (
          <Typography
            key={item.name}
            {...(item.route ? routeComponent : linkComponent)}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            variant="button"
            textTransform="initial"
            minWidth={item.description ? '14rem' : '10rem'}
            // color={item.description ? 'dark' : 'text'}
            fontWeight={item.description ? 'bold' : 'regular'}
            py={item.description ? 1 : 0.625}
            px={2}
            sx={({ palette: { grey, dark } }) => ({
              borderRadius: 'md',
              cursor: 'pointer',
              transition: 'all 300ms linear',
              textDecoration: 'none',
              '&:hover': {
                backgroundColor: grey[200],
                fontWeight: 'bold',
                // color: '#fafafa',

                // '& *': {
                //   color: '#fafafa',
                // },
              },
            })}
            onMouseEnter={({ currentTarget }) => {
              if (item.dropdown) {
                setNestedDropdown(currentTarget);
                setNestedDropdownEl(currentTarget);
                setNestedDropdownName(item.name);
              }
            }}
            onMouseLeave={() => {
              if (item.dropdown) {
                setNestedDropdown(null);
              }
            }}
          >
            {item.description ? (
              <Box display="flex" alignItems="center">
                <img src={item.icon} alt="logo" width="32px" />
                <Box ml={2} sx={{ color: '#101828' }}>
                  {item.name}
                  <Typography
                    display="block"
                    variant="button"
                    fontWeight="regular"
                    sx={{ transition: 'all 300ms linear', color: '#667085', textTransform: 'inherit' }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Box>
            ) : (
              item.name
            )}
            {/* {item.collapse && <Iconify icon="bx:chevron-down" />} */}
          </Typography>
        );
      });
    } else if (collapse && name === dropdownName && listStyle === 'grid') {
      template = (
        <Grid key={name} container spacing={2} py={1} px={1.5} mt={1}>
          <Grid item xs={12} sm={9} md={9} lg={9}>
            <Grid container direction="direction">
              {collapse.map((item) => {
                const linkComponent = {
                  component: Link,
                  href: item.href,
                  target: '_blank',
                  rel: 'noreferrer',
                };

                const routeComponent = {
                  component: RouterLink,
                  to: item.route,
                };

                return (
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography
                      key={item.name}
                      {...(item.route ? routeComponent : linkComponent)}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      variant="button"
                      textTransform="initial"
                      minWidth={item.description ? '14rem' : '10rem'}
                      // color={item.description ? 'dark' : 'text'}
                      fontWeight={item.description ? 'bold' : 'regular'}
                      py={item.description ? 1 : 0.625}
                      px={2}
                      sx={({ palette: { grey, dark } }) => ({
                        borderRadius: 'md',
                        cursor: 'pointer',
                        transition: 'all 300ms linear',
                        textDecoration: 'none',
                        '&:hover': {
                          backgroundColor: grey[200],
                          fontWeight: 'bold',
                          // color: '#fafafa',

                          // '& *': {
                          //   color: '#fafafa',
                          // },
                        },
                      })}
                      onMouseEnter={({ currentTarget }) => {
                        if (item.dropdown) {
                          setNestedDropdown(currentTarget);
                          setNestedDropdownEl(currentTarget);
                          setNestedDropdownName(item.name);
                        }
                      }}
                      onMouseLeave={() => {
                        if (item.dropdown) {
                          setNestedDropdown(null);
                        }
                      }}
                    >
                      {item.description ? (
                        <Box display="flex" alignItems="center">
                          <img src={item.icon} alt="logo" width="32%" style={{ borderRadius: '6px' }} />
                          <Box ml={2} sx={{ color: '#101828' }}>
                            {item.name}
                            <Typography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              sx={{ transition: 'all 300ms linear', color: '#667085', textTransform: 'inherit' }}
                            >
                              {item.description}
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        item.name
                      )}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3} sx={{ background: '#F4FBF8' }}>
            <Typography
              // {...(item.route ? routeComponent : linkComponent)}
              // component={RouterLink}
              // to={isLogin && userRole === 'USER_DTC' ? '/auth/subscribe' : '/explore-course'}
              // display="flex"
              // justifyContent="space-between"
              // alignItems="center"

              component={Link}
              href="https://www.keyskillset.com/courses"
              target="_blank"
              rel="noreferrer"
              variant="button"
              px={2}
              sx={({ palette: { grey, dark } }) => ({
                borderRadius: 'md',
                cursor: 'pointer',
                transition: 'all 300ms linear',
                textTransform: 'inherit',
                textDecoration: 'none',
                '&:hover': {
                  backgroundColor: grey[200],
                  fontWeight: 'bold',
                },
              })}
              // onMouseEnter={({ currentTarget }) => {
              //   if (item.dropdown) {
              //     setNestedDropdown(currentTarget);
              //     setNestedDropdownEl(currentTarget);
              //     setNestedDropdownName(item.name);
              //   }
              // }}
              onMouseLeave={() => {
                // if (item.dropdown) {
                setNestedDropdown(null);
                // }
              }}
            >
              {/* <img src={courseCreation} alt="course" width={'60%'} style={{ marginTop: '-1rem' }} /> */}
              <Box sx={{ color: '#101828', textTransform: 'inherit' }}>
                Explore More...
                <Typography
                  display="block"
                  variant="button"
                  fontWeight="regular"
                  sx={{
                    transition: 'all 300ms linear',
                    color: '#667085',
                    maxWidth: '95%',
                    textTransform: 'inherit !important',
                  }}
                >
                  Our courses are designed to build memory retention for a superior learning experience and knowledge
                  building. Be 3X faster & efficient.
                </Typography>
              </Box>
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return template;
  });

  // Routes dropdown menu
  const dropdownMenu = (
    <Popper
      anchorEl={dropdown}
      popperRef={null}
      open={Boolean(dropdown)}
      //   open={test}

      placement="top-start"
      transition
      style={{ zIndex: 1111 }}
      modifiers={[
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      onMouseEnter={() => setDropdown(dropdownEl)}
      onMouseLeave={() => {
        if (!nestedDropdown) {
          setDropdown(null);
          setDropdownName('');
        }
      }}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          sx={{
            transformOrigin: 'left top',
            background: '#fff',
            boxShadow: '0px 2px 15px -10px #6a6767',
          }}
        >
          <Box borderRadius="lg">
            <Typography variant="body2">
              {/* <Iconify ref={setArrowRef} sx={{ mt: -6 }} icon="bx:chevron-down" /> */}
            </Typography>
            <Box shadow="lg" borderRadius="lg" p={2} mt={0.6}>
              {renderRoutes}
            </Box>
          </Box>
        </Grow>
      )}
    </Popper>
  );
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState(0);

  const getCourserList = async (page, searchText, rowsPerPage) => {
    const result = await adminServices.getCourseLists(page + 1, searchText, rowsPerPage);
    if (result.ok) {
      setSearchText(result.data[0])
      console.log("response of getCourse List",result)
      
    }
  };
  return (
    <>
      <ThemeProvider>
        <MainRootStyle>
          <RootStyle>
            <Container className={classes.container}>
              <ToolbarStyle>
                {/* <Box
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={openMobileNavbar}
              >
                <img src={Logo} width="110px" alt="logo" />
              </Box> */}
                <Hidden mdUp>
                  <IconButton onClick={openMobileNavbar}>
                    <Iconify icon="charm:menu-hamburger" />
                  </IconButton>
                </Hidden>
                <Hidden mdUp>
                  <Box onClick={() => navigate('/auth/subscribe')} sx={{ cursor: 'pointer' }}>
                    <img src={Logo} width="90px" alt="logo" />
                  </Box>
                </Hidden>

                <Hidden mdDown>
                  <Box onClick={() => navigate('/auth/subscribe')} sx={{ cursor: 'pointer' }}>
                    <img src={Logo} alt="logo" width="110" style={{ margin: 'auto' }} />
                  </Box>
                </Hidden>
                {
                  isLogin ? (
                    <ClickAwayListener
                      onClickAway={() => {
                        setDropdown(null);
                        setDropdownName('');
                      }}
                    >
                      <Box color="inherit" display={{ xs: 'none', sm: 'none', md: 'flex' }} ml="auto">
                        {renderNavbarItems}
                      </Box>
                    </ClickAwayListener>
                  ) : null
                }

                <Box sx={{ flexGrow: 1 }} />
                {/* add search bar in top navbar */}
                {/* {
                  isLogin && (
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} sm={6} md={8}> 
                      <TextField
                        fullWidth
                        sx={{
                          border: 'black',
                          marginLeft: { xs: '10px', sm: '20px', md: '130px' }, // Adjust margin based on screen size
                        }}
                            onChange={(e) => {
                              setSearchText(e.target.value !== null ? e.target.value : '');
                            }}
                        placeholder="Search for courses"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon
                              onClick={()=>{
                                getCourserList(page, searchText, rowsPerPage)
                              }}
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                  )
                } */}

                <Box
                  sx={{
                    display: 'flex',
                    marginRight: '14px',
                    '@media (max-width: 362px)': {
                      marginRight: '0px',
                      marginLeft: '14px',
                    },
                  }}
                >
                  {/* <SmartDisplayIcon className={classes.videoIcon} /> &nbsp;&nbsp;
                  <Typography onClick={() => setOpenContent(true)} color="primary" className={classes.watchDemo}>
                    Watch Demo
                  </Typography> */}
                  {
                    isLogin || location.pathname === "/sign-up" ? (
                      <>
                        <SmartDisplayIcon className={classes.videoIcon} />
                        <Typography onClick={() => setOpenContent(true)} color="primary" className={classes.watchDemo}>
                          Watch Demo
                        </Typography>
                      </>
                    ) : null
                  }
                </Box>
                {
                  location.pathname === "/sign-up" && !isLogin && <LoginForm />
                }
                {/* {!isLogin && <LoginForm />} */}
                {/* {isLogin && userRole === 'USER_DTC' && (
                  <Typography
                    // variant="button"
                    color="primary"
                    component={RouterLink}
                    px={2}
                    sx={({ palette: { grey, dark } }) => ({
                      borderRadius: 'md',

                      textDecoration: 'none',
                    })}
                    to="/auth/my-courses"
                  >
                    My Courses
                  </Typography>
                )} */}
                {isLogin && <AccountPopover style={{ paddingLeft: 8 }} />}

                {/* {isLogin && (
                  <Hidden smDown>
                    <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={() => navigate('/sign-up')}
                      >
                        Sign up
                      </Button>
                    </Stack>
                  </Hidden>
                )} */}

                <Box bgColor={'transparent'} shadow={'none'} borderRadius="xl" px={0}>
                  {mobileView && (
                    <MobileNavbar openNav={mobileNavbar} toggleNavbar={() => setMobileNavbar(!mobileNavbar)} />
                  )}
                </Box>
              </ToolbarStyle>
              {dropdownMenu}
            </Container>
          </RootStyle>

          <VideoModal
            openModel={openContent}
            closeModel={() => setOpenContent(!openContent)}
            title={'How our platform works'}
          >
            <iframe
              width="100%"
              className={classes.iframeAlign}
              src="https://www.youtube.com/embed/_Tnm3ojIADo"
              title="KeySkillset Platform End User Experience | Excel and Financial Modeling Demo"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />

            {/* <Video controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}>
                  <source src="https://youtu.be/AgLC3BSvpNU" type="video/webm" />
                  <track label="English" kind="subtitles" srcLang="en" src="https://youtu.be/AgLC3BSvpNU" default />
                </Video> */}
          </VideoModal>

          <MainStyle>
            <Outlet />
            <BacKToTop />
          </MainStyle>
        </MainRootStyle>
        {/* </ClickAwayListener> */}
      </ThemeProvider>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  iframeAlign: {
    height: '515px',
    width: '100%',
    '@media (max-width: 768px)': {
      height: '350px !important',
    },
    '@media (max-width: 600px)': {
      height: '230px !important',
    },
  },
  linkText: {
    color: '#000',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  linkTextActive: {
    color: palette.primary.main,
    fontSize: '1.2rem',
  },
  logo: {
    // '&:hover': {},
  },
  menuItem: {
    padding: '0px 12px',
    color: '#667085',
    fontSize: '1rem',
  },
  button: {
    borderRadius: '28px',
  },
  container: {
    maxWidth: '1440px',
    '@media (min-width: 601px)': {
      padding: '0px 2rem',
    },

    '@media (min-width: 1201px)': {
      padding: ' 0px 112px',
    },
    '@media (max-width: 600px)': {
      padding: '0px 1rem',
    },
  },
  watchDemo: {
    textDecoration: 'none',
    fontSize: '1rem',
    cursor: 'pointer',
    '@media (max-width: 362px)': {
      lineHeight: 1,
      marginTop: '8px',
    },
  },

  videoIcon: {
    color: '#FE7000',
    '@media (max-width: 362px)': {
      marginTop: '12px',
    },
  },
}));
