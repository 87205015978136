/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import { Typography, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

import adminServices from '../services/adminServices';

export const DashboardLineChart = (props) => {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState('last7days');
  const [dashBoardChartData, setDashBoardChartData] = useState({});
  const { t } = useTranslation('dashboard'); 
  const [loading, setLoading] = useState(true);

  function getDateRange(option) {
    const today = new Date();
    let lastWeek;
    let firstDayOfMonth;
    let firstDayOfLastMonth;
    let lastDayOfLastMonth;
    switch (option) {
      case 'last7days':
        lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        return { fromDate: lastWeek, toDate: today };
      case 'currentMonth':
        firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        return { fromDate: firstDayOfMonth, toDate: today };
      case 'lastMonth':
        firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        return { fromDate: firstDayOfLastMonth, toDate: lastDayOfLastMonth };
      default:
        return '';
    }
  }

  const getDashBoardChart = async (result) => {
    try {
      const response = await adminServices.getDashBoardChart(
        moment(result.fromDate).format('YYYY-MM-DD'),
        moment(result.toDate).format('YYYY-MM-DD')
      );
      if (response.ok) {
        setDashBoardChartData(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // setDateRange(getDateRange(selectedOption));
    getDashBoardChart(getDateRange(selectedOption));
  }, []);

  const series = dashBoardChartData?.userDetails || [];

  const dataTotals = series.map((item) => {
    const total = item.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return { name: item.name, total };
  });

  const dateOptions = [
    { value: 'last7days', label: t('last7days') },
    { value: 'currentMonth', label: t('currentMonth') },
    { value: 'lastMonth', label: t('lastMonth') },
  ];

  const options = {
    chart: {
      height: 326,
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      fontFamily: 'Inter,SemiBold',
      fontWeight: 500,
      customLegendItems: [
        `${t('newSignUps')} (${dataTotals[0]?.total})`,
        `${t('subscriptions')} (${dataTotals[1]?.total})`,
        `${t('canceledSubscriptions')} (${dataTotals[2]?.total})`,
      ],
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        formatter: function (val) {
          return val;
        },
        style: {
          fontWeight: 500,
          fontFamily: 'Inter,SemiBold',
        },
      },
    },
    xaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          fontWeight: 500,
          fontFamily: 'Inter,SemiBold',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [2, 2, 2],
      curve: 'straight',
      dashArray: [0, 0, 0],
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: dashBoardChartData?.date || [],
    },
    grid: {
      borderColor: '#f1f1f1',
    },
  };

  return (
    <div>
      <div className={classes.title}>
        <Typography variant="h5" sx={{ fontWeight: '550' }}>
          {t('registrationVsSubscriptions')}
        </Typography>

        <div className={classes.dropdown}>
          <Typography>{t('view')}</Typography>
          <Select
            sx={{
              '.css-1gn4piy-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                padding: '6.5px 14px',
              },
              marginLeft: '0.5rem',
              borderRadius: '4px ! important',
            }}
            value={selectedOption}
            onChange={async (e) => {
              setSelectedOption(e.target.value);
              const result = await getDateRange(e.target.value);
              getDashBoardChart(result);
            }}
          >
            {dateOptions.map((option) => (
              <MenuItem sx={{ padding: '6px ! important' }} key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div style={{ borderBottom: '1px solid #80808038' }} />
      <ReactApexChart options={options} series={series} type="line" height={326} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  title: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '1rem' },
  dropdown: {
    display: 'flex',
    alignItems: 'center',
  },
}));
