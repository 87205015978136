import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Typography, Tabs, Tab } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PropTypes from 'prop-types';

import Page from '../../../components/Page';
import PageHeader from '../../../components/PageHeader';
import SubscriptionInfo from './SubscriptionInfo';
import SubscriptionPlans from './SubscriptionPlans';
import subscriptionServices from '../../../services/subscriptionServices';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Subscriptions = () => {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation('productsPlans'); 
  const [loading, setLoading] = useState(true);
  const [subscriptionInfoData, setSubscriptionInfoData] = useState([]);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getSubscriptionInfo = async () => {
    try {
      const response = await subscriptionServices.getSubscriptionInfo();
      if (response.ok) {
        setSubscriptionInfoData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getSubscriptionPlans = async () => {
    setLoading(true);
    try {
      const response = await subscriptionServices.getSubscriptionPlans();
      if (response.ok) {
        setSubscriptionPlans(response.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getSubscriptionInfo();
    getSubscriptionPlans();
  }, []);

  const Breadcrumb = () => (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      <Typography
        sx={{ textDecoration: 'none' }}
        variant="body2"
        color="primary"
        component={RouterLink}
        to="/app/dashboard"
      >
        {t('dashboard')}
      </Typography>
      <Typography variant="body2" color="textPrimary">
        {t('products')}
      </Typography>
    </Breadcrumbs>
  );

  return (
    <Page title={'Subscriptions'}>
      <PageHeader pageTitle={t('productsPlans')} breadcrumbs={<Breadcrumb />} />

      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab iconPosition="start" icon={<CurrencyRupeeIcon />} label={t('productsPricing')} {...a11yProps(0)} />
            <Tab iconPosition="start" icon={<EventRepeatIcon />} label={t('billingType')} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <SubscriptionPlans data={subscriptionPlans} getData={() => getSubscriptionPlans()} dataLoading={loading} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SubscriptionInfo data={subscriptionInfoData} getData={() => getSubscriptionInfo()} loading={loading} />
        </TabPanel>
      </Box>
    </Page>
  );
};

export default Subscriptions;
