/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../../src/website/pages/i18n'; 
import { Container, Grid, Typography, Link, Box, TextField, Hidden, AppBar, Toolbar } from '@mui/material';
import { Link as RouterLink, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import Cookies from 'js-cookie';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useDispatch } from 'react-redux';
import jwtdecode from 'jwt-decode';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BackgroundImg from '../../assets/logo/images/bg.jpg';
import BackgroundImgs from '../../assets/logo/images/Register.png';
import BackgroundImges from '../../assets/logo/images/BackgroundImgs.png';
import BackgroundImges1 from '../../assets/logo/images/backgroundImage1.png';
import BackgroundImges2 from '../../assets/logo/images/backgroundImage2.png';
import BackgroundImges3 from '../../assets/logo/images/backgroundImage3.png';
import BackgroundImges4 from '../../assets/logo/images/backgroundImage4.png';
import BackgroundImges5 from '../../assets/logo/images/backgroundImage5.png';
import Page from '../../components/Page';
import LanguageSwitcher from '../../../src/website/pages/LanguageSwitcher';
import loginServices from '../../services/loginServices';
import SnackBar from '../../components/snackbar/snackbar';
import apiClient from '../../services/apiClient';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import ThemeProvider from '../../theme';
import Logo from '../../assets/logo/logo.png';
import {
  loginSuccess,
  openSubscriptionModal,
  setSubscribedCourses,
  setOpenSubscriptionModalDetails,
  openSnackbar,
} from '../../store/reducer';
import trialAndStripeSubscriptionService from '../../services/trialAndStripeSubscriptionService';
import courseApi from '../../services/users/courseApi';
const settings = require('../../settings');
// import apiUrl from '../../settings';
export function Timer({ initialTime }) {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(prevTime => {
        if (prevTime === 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [initialTime]);

  return (
    <Grid item xs={12} md={6} style={{marginTop:"5px"}} >
      <Typography style={{ fontSize: "11px" }}>
        <span></span>You can re-send OTP in {time} sec
      </Typography>
    </Grid>
  );
}

export default function Index() {
  const webClientId = process.env.REACT_APP_CLIENT_ID;
  console.log('webClientId', webClientId);
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation(); 
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [forgotOTP, setforgotOTP] = useState(true);
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [otpVisible, setotpVisible] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [disableEmail, setDisableEmail] = useState(false);
  const [announcementVisible, setAnnouncementVisible] = useState(true);
  const [resendOTPText, setResendOTPText] = useState(false);
  const [sentOTPText, setSentOTPText] = useState(false);
  /* useEffect(() => {
    gapi.load("client:auth2", () => {
     gapi.client.init({
       clientId:
       webClientId,
       plugin_name: "email",
     });
   }); 
 }, []); */

  const [showResendButton, setShowResendButton] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [initialTextVisible, setInitialTextVisible] = useState(true);

  useEffect(() => {
    let timeoutId;
    if (otpVisible) {
      timeoutId = setTimeout(() => {
        setInitialTextVisible(false);
        setShowResendButton(true);
      }, 30000);

      timeoutId = setTimeout(() => {
        setShowResendButton(true);
      }, 60000);

      setOtpSent(true);
    }
    return () => clearTimeout(timeoutId);
  }, [otpVisible]);

  const images = [BackgroundImges1, BackgroundImges2, BackgroundImges3, BackgroundImges4, BackgroundImges5];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 4000); // Change image every 4 seconds
    return () => clearInterval(interval);
  }, []);

  const generateOTP = async (values) => {
    console.log(values);
    setLoading(true);
    const data = {
      emailAddress: values.email,
    };

    const response = await loginServices.generateOTP(data);
    if (response.ok) {
      setSnackbarTitle(response.data);
      setOpenSnackbar(true);
      setotpVisible(true)
      setDisableEmail(true)
    } else {
      setSnackbarTitle(response.data.message);
      setOpenSnackbar(true);
    }
    setLoading(false);
  };



  const handleSubmitForm = async (values) => {
    setLoading(true);
    const data = {
      emailAddress: values.email,
      password: values.password,
    };

    const response = await loginServices.userLogin(data);
    console.log('normal login response....', response);
    if (response.ok) {

      apiClient.setHeader('Authorization', `BEARER ${response.data.token}`);
      Cookies.set('token', response.data.token);
      Cookies.set('refreshToken', response.data.refreshToken);
      Cookies.set('isLogIn', true);
      const decoded = await jwtdecode(response.data.token);
      Cookies.set('tokenExpireTime', decoded.exp);
      console.log(decoded);
      await getLoginUserDetails();
    } else if (!response.ok) {
      if (response?.data?.message) {
        if (typeof response.data.message === 'object') {
          setSnackbarTitle(response.data.message.password);
        } else {
          setSnackbarTitle(response.data.message);

          // dispatch(openSnackbar(response.data.message));
        }
      } else {
        setSnackbarTitle('Something went wrong, Please try again.');
      }
      setOpenSnackbar(true);
    }
    setLoading(false);
  };

  const postTrialCourseDetails = async (planId) => {
    try {
      const res = await trialAndStripeSubscriptionService.postTrialCourseEnrollmentDetails(
        JSON.stringify({ planId: planId })
      );
      if (res.ok) {
        await getCourserList();
        dispatch(openSnackbar('Trial Course Subscribed Successfully.'));
        navigate('/auth/my-courses');
      } else {
        if (res?.data?.message) {
          dispatch(openSnackbar(res.data.message));
        } else {
          dispatch(openSnackbar('Failed to Subscribe Trial Course, Please try again.'));
        }
        navigate('/auth/my-courses');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCourserList = async () => {
    try {
      const response = await courseApi.getCourseList();
      if (response.ok) {
        dispatch(setSubscribedCourses(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postSubscribeDetails = async (planId) => {
    dispatch(setOpenSubscriptionModalDetails(location?.state?.openSubscriptionModalDetails));
    dispatch(openSubscriptionModal(planId));
    navigate('/auth/subscribe', { replace: true });
    // window.location.href = `${apiUrl}/auth/my-courses`;
    // window.location.reload();
  };

  const getLoginUserDetails = async () => {
    try {
      const response3 = await loginServices.getUserInfo();
      if (response3.ok) {
        dispatch(loginSuccess(response3.data));
        console.log('response data', response3.data)

        if (response3.data.role === 'CONTENT_WRITER' || response3.data.role === 'AUTH_USER') {
          navigate('/app/course', { replace: true });
        } else if (response3.data.role === 'USER_DTC') {
          await getCourserList();
          if (location?.state?.from === 'trialButton') {
            await postTrialCourseDetails(location?.state?.planId);
          } else if (location?.state?.from === 'subscribeButton') {
            await postSubscribeDetails(location?.state?.planId);
          } else {
            if (searchParams.get('isSubscribe')) {
              navigate('/auth/subscribe', { replace: true });
            } else {
              navigate('/auth/subscribe', { replace: true });
            }
          }
        } else {
          navigate('/app/dashboard', { replace: true });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickShowPassword = () => {
    setVisible(!visible);
  };
  // Function to handle edit icon click
  const handleEditClick = () => {
    setotpVisible(false);
  };
 
  // const handleSubmitFormGoogleUser = async (values) => {
  //   setgLoading(true);
  //   try{
  //     console.log('login values',values)
  //     const data = values;
  //     const response = await loginServices.userLogin(data);
  //     console.log('login reponse',response);
  //     if (response.ok) {

  //       apiClient.setHeader('Authorization', `BEARER ${response.data.token}`);
  //       Cookies.set('token', response.data.token);
  //       Cookies.set('refreshToken', response.data.refreshToken);
  //       Cookies.set('isLogIn', true);
  //       const decoded = await jwtdecode(response.data.token);
  //       Cookies.set('tokenExpireTime', decoded.exp);
  //       console.log(decoded);
  //       await getLoginUserDetails();
  //     } 
  //     else if (!response.ok) {
  //       if (response?.data?.message) {
  //         if (typeof response.data.message === 'object') {
  //           console.log('inside 1 cond')
  //           dispatch(openSnackbar(response.data.message.password));
  //         } 
  //         else {
  //           console.log('inside 2 cond')
  //           dispatch(openSnackbar(response.data.message));
  //         }
  //       } else {
  //       console.log('inside 3 cond')
  //       dispatch(openSnackbar('Something went wrong, Please try again.'));
  //       }
  //   }
  // }
  // catch (error) {
  // console.error('Error during Google login:', error);
  // dispatch(openSnackbar('Something went wrong, please check if you had signed up with OTP.'));
  // } finally {
  //   setgLoading(false);
  //   }
  // };
  // const onSuccess=(response)=>{
  //   console.log('Login success currentUser:::',response);
  //   const jsonData={
  //     "emailAddress":response.profileObj.email,
  //     "googleId":response.profileObj.googleId,
  //     "firstName":response.profileObj.givenName,
  //     "lastName":response.profileObj.familyName
  //   }
  //   console.log('jsonData',jsonData);
  //   handleSubmitFormGoogleUser(jsonData);
  //   //check if the user exists in database then get the user role
  //   // else give the first time login screen

  //  // verifyGoogleToken(response);
  //  // refreshTokenSetup(response);

  //     /* const client = Google.accounts.oauth2.initCodeClient({
  //     client_id: '974287529513-82gvs1qmv45bfrss69i15svoqdso3//7mg.apps.googleusercontent.com',
  //     scope: 'https://www.googleapis.com/auth/userinfo.profile',
  //     ux_mode: 'popup',
  //     callback: (response) => {
  //       if(response.code){
  //         console.log(response.code)
  //       }
  //     },
  //   });
  //        client.requestCode(); */
  // };
  // const onFailure=(response)=>{
  //   console.log('Login failed res',response)

  // };
  


  return (
    <Box className={classes.mainContainer}>
     {process.env.REACT_APP_ENV === 'dev' &&  <LanguageSwitcher />}
      <Container maxWidth="lg">
        <Grid container spacing={2} alignItems="stretch">
          {/* <Grid item xs={12} md={6.5} className={classes.boxContainer} style={{ backgroundImage: `url(${images[index]})`}}></Grid> */}
          <Grid item xs={12} md={7} >
            <Carousel autoPlay
              interval={7000}
              showArrows={false}
              showStatus={false}
              axis='horizontal'
              infiniteLoop
              showThumbs={false}
              showIndicators={false}
              className={classes.carouselContainer}
            >
              {images.map((image, idx) => (
                <div key={idx}>
                  <img alt={`Image ${idx + 1}`} src={image} style={{ width: '100%', height: '540px' }} />
                </div>
              ))}
            </Carousel>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box className={classes.formcontainer}>
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().required('Email is required').email()
                })}
                onSubmit={(values) => {
                  if (otpVisible)
                    handleSubmitForm(values);
                  else
                    generateOTP(values)
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values,
                }) => (
                  <Form>
                    <Grid container spacing={2} >
                      <Grid item xs="12">
                        <Typography color="#000000" className={classes.login} align="left" variant="h4">
                        {t('login_to_keySkillset')}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          // sx={{
                          //   maxWidth:"500px"
                          // }}
                          // className={classes.emailTextfield}
                          fullWidth
                          // label="Register Email ID"
                          placeholder={t('register_email')}
                          name="email"
                          variant="outlined"
                          color="success"
                          // onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue('email', e.target.value.trim());
                            setFieldValue('password', '');
                          }}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          value={values.email}
                          disabled={disableEmail}
                          InputProps={{
                            endAdornment: (
                              <IconButton onClick={() => setDisableEmail(false)}>
                                <EditIcon 
                                fontSize="small"
                                 style={{ color: '#BCBCBC' }}
                                 onClick={()=>{
                                  handleEditClick();
                                 }}
                                  />
                              </IconButton>
                            ),
                          }}
                        />
                        {/* <IconButton onClick={() => setDisableEmail(false)}>
                            <EditIcon fontSize="small" style={{ color: '#BCBCBC' }} />
                          </IconButton> */}
                      </Grid>
                      {otpVisible &&  <Grid item xs="12">
                        {/* <Typography className={classes.email} variant="subtitle1">
                            OTP
                          </Typography> */}
                        <TextField
                          fullWidth
                          sx={{
                            marginTop: "20px"
                          }}
                          name="password"
                          variant="outlined"
                          color="success"
                          // label="OTP"
                          placeholder='OTP'
                          type={visible ? 'text' : 'password'}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue('password', e.target.value.trim());
                          }}
                          error={Boolean(touched.password && errors.password)}
                          value={values.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {/* <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                                              {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton> */}
                                {visible ? (
                                  <VisibilityIcon
                                    className={classes.visibleIcon}
                                    onClick={handleClickShowPassword}
                                  />
                                ) : (
                                  <VisibilityOffIcon
                                    className={classes.visibleIcon}
                                    onClick={handleClickShowPassword}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>}
                      <Box sx={{ marginTop: "25px", marginLeft: '16px' }}>
                        {
                          otpVisible && resendOTPText && (
                            <Grid item xs={12}>
                              <Typography className={classes.resendotp_text} >
                              {t('resend_otp')}
                              </Typography>
                            </Grid>
                          )
                        }
                        {
                          sentOTPText && otpVisible ? (
                            <Grid item xs={12}>
                              <Typography className={classes.resendotp_text} >
                              {t('ent')}
                              </Typography>
                            </Grid>
                          ) : null
                        }
                      </Box>
                      {/* Password field */}
                      <Grid item xs="12">
                        {otpVisible ? (
                          // <LoadingButton
                          //   fullWidth
                          //   size="medium"
                          //   type="submit"
                          //   variant="contained"
                          //   loading={loading}
                          //   sx={{ backgroundColor: '#D52F37'}}
                          // >
                          //   Login
                          // </LoadingButton>
                          <Grid container spacing={2} columns={{ xs: 4, md: 12 }} sx={{ marginTop: "3px" }} >
                            <Grid item xs={12} md={3}>
                              <LoadingButton
                                // fullWidth
                                size="medium"
                                type="submit"
                                variant="contained"
                                loading={loading}
                                sx={{ backgroundColor: '#D52F37', borderRadius: '5px' }}
                              >
                                {t('login')}
                              </LoadingButton>
                            </Grid>
                            {
                              showResendButton && otpSent ? (
                                <Grid item xs={12} md={4}>
                                  <Typography className={classes.resendotpbutton}
                                    onClick={() => {
                                      generateOTP(values);
                                      setResendOTPText(true)
                                      setSentOTPText(false);
                                    }
                                    }
                                    onMouseEnter={(e) => {
                                      e.currentTarget.style.backgroundColor = '#f0f0f0'; // Change background color on hover
                                    }}
                                    onMouseLeave={(e) => {
                                      e.currentTarget.style.backgroundColor = 'transparent'; // Restore background color on mouse leave
                                    }}
                                  >
                                    {t('resent_otp')}

                                  </Typography>
                                </Grid>
                              ) :
                                <Timer initialTime={30} />
                            }
                            {/* <Grid item xs={12} md={4}>
                              <Typography className={classes.resendotpbutton}
                                onClick={() => {
                                  generateOTP(values);
                                  setResendOTPText(true)
                                  setSentOTPText(false);
                                }
                                }
                              >
                                Resend OTP
                              </Typography>
                            </Grid> */}
                          </Grid>
                        ) : (
                          <Grid item xs={12}>
                            <LoadingButton
                              // className={classes.generateOTP}
                              // fullWidth
                              size="medium"
                              type="submit"
                              variant="contained"
                              loading={loading}
                              sx={{ backgroundColor: '#D52F37', borderRadius: '5px' }}
                              onClick={() => {
                                setSentOTPText(true);
                                setFieldValue('password', '');
                              }}
                            >
                              {t('generate_otp')}
                            </LoadingButton>
                          </Grid>)
                        }
                      </Grid>
                      {/* Add  password field code here */}
                      {/* Alert message */}

                      <Grid item xs={12} md={12} className={classes.otpalert}  >
                        <Alert
                          severity="info"
                          sx={{
                            fontSize: "10px",
                            color: '#4B8EFD',
                            p: 0, // Padding
                            m: 1, // Margin
                          }}
                        >{t('seamless_login_message')}</Alert>
                      </Grid>
                      <Grid xs={12} className={classes.whiteBorder}>
                        <span>  </span>
                      </Grid>
                      {/* Sign-up link */}
                      <Grid item xs="12">
                        <Typography className={classes.email}>
                          {t('dont_have_account')}
                          <RouterLink
                            to={{
                              pathname: '/sign-up',
                              // search: '?isSubscribe=true',
                            }}
                            // state={location.state}
                            className={classes.signup}
                          >
                            {t('reg_now')}
                          </RouterLink>
                        </Typography>
                      </Grid>
                      {/* Help link */}
                      {/* <Grid item xs="12">
                        <Typography className={classes.email}>
                          For login related issue, &nbsp;
                          <RouterLink
                            to={{
                              // pathname: '/sign-up',
                              // search: '?isSubscribe=true',
                            }}
                            // state={location.state}
                            className={classes.signup}
                          >
                            Get Help
                          </RouterLink>
                        </Typography>
                      </Grid> */}
                      {/*  <Grid item xs="12">
                                  <div className={classes.orContainer}>
                                    <div className={classes.line}></div>
                                      <Typography className={classes.orText}>OR</Typography>
                                    <div className={classes.line}></div>
                                  </div>
                            </Grid> */}
                      <Grid item xs="12" align="center" color="#f13900">
                        {/* <script src="https://apis.google.com/js/platform.js" async defer></script> */}

                        {/*  {<GoogleLogin
                                        clientId = {webClientId}
                                        buttonText={gLoading ?"Signing in..":"Continue with Google"}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        disabled={gLoading}
                                      />} */}
                        {/* {otpVisible && <Grid align="center" item xs="12">
                            <Typography variant="body2" align="center" sx={{ mt: 3, cursor: 'pointer' }}>
                              Resend OTP?{' '}
                              <Link variant="subtitle2" onClick={() => generateOTP(values)}>
                                Click here
                              </Link>
                            </Typography>
                          </Grid>} */}
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* <SnackBar open={false} snackbarTitle={''} close={() => {}} /> */}
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </Box>
  );
}
const useStyles = makeStyles((theme) => ({
  Maxheight: {
    height: '100vh',

    // [theme.breakpoints.up('xl')]: {
    //   height: '71.2vh',
    // },

    [theme.breakpoints.down('lg')]: {
      height: '100vh !important',
    },
    [theme.breakpoints.down('md')]: {
      height: '100vh',
    },
  },
  containerMaxWidth: {
    maxWidth: '100%', 
    // width: '100% !important',
    padding: '0px',
    margin: '0 auto',
  },
  // mainContainer:{
  //   display:"flex",
  //   justifyContent:"flex-start",
  //   padding:"30px 50px",
  //   '@media (max-width: 767px)': {
  //     // height: '240px',
  //     // marginTop: '70px',
  //     // display:"flex",
  //     // padding: "20px 30px",
  //     padding: "20px 0",
  //   },
  // },
  mainContainer: {
    padding: "30px 0",
    background: "#F0F0F0",
    height: '90vh',
    marginTop: "4rem",
    '@media (max-width: 767px)': {
      padding: "20px 0",
    },
  },
  containerWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
  },
  // formcontainer:{
  //   background:"#FFFFFF",
  //   //  width:"600px",
  //   //  margin:"10px",
  //   flexGrow:1,
  //   '@media (max-width: 767px)': {
  //     // height: '240px',
  //     // marginTop: '70px',
  //     display:"flex",
  //   },

  // },
  formcontainer: {
    background: "#FFFFFF",
    padding: '36px',
    borderRadius: "20px",
    marginTop: "4px",
    // minWidth:"509px",
    minHeight: "532px",
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  generateOTP:
  {
    width: '70px',
    height: "35px"
  },
  dotColorS: {
    background: '#00B673',
    height: '6px',
    width: '6px',
    borderRadius: '50%',
    display: 'inline-block',
    margin: '6px 0px 0px 0px',
  },
  dotColor: {
    background: '#00B673',
    height: '6px',
    width: '6px',
    borderRadius: '50%',
    display: 'inline-block',
    margin: '6px 0px 0px 0px',
  },
  listNameWidth: {
    color: '#FFFFFF',
    fontSize: '0.9rem',
    fontWeight: '300',
    padding: '0px 0px 0px 8px',
    maxWidth: '85%',
  },
  listName: {
    color: '#FFFFFF',
    fontSize: '0.9rem',
    fontWeight: '300',
    padding: '0px 0px 0px 8px',
    maxWidth: '82%',

    // marginTop: '1rem',
  },
  mainContents: {
    marginTop: '3rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '1.5rem',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleName: {
    color: '#FFB880',
    fontSize: '1rem',
    fontWeight: '400',
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.5rem',
      fontWeight: '400',
    },
  },
  imgContent: {
    background: '#0000001f',
    padding: '3rem 2rem 0rem 2rem',
    // opacity: 0.5,
    [theme.breakpoints.down('md')]: {
      padding: '2rem 2rem 0rem 2rem',
    },
  },
  boxContainer: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: "532px",
    marginTop: "15px",
    borderRadius: "20px",
    animationName: '$slideInRight',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    '@media (max-width: 767px)': {
      height: '240px',
      marginTop: '70px'
    },
  },
  gridContainer: {
    display: ' flex',
    justifyContent: 'end',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 3px 6px #00000029',
    [theme.breakpoints.down('md')]: {
      marginTop: '13rem',
    },
  },
  orContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 0), // Adjust the margin as needed
  },
  line: {
    flex: 1,
    borderTop: `1px solid ${theme.palette.text.secondary}`,
  },
  orText: {
    margin: theme.spacing(0, 2), // Adjust the margin as needed
    color: theme.palette.text.secondary,
    fontSize: '0.8rem', // Adjust the font size as needed
  },
  grid: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  form: {
    paddingBottom: '2rem',
    paddingTop: '2rem',
  },
  divider: {
    height: '2px',
    width: '145px',
    backgroundColor: 'black',
  },
  // keySkillsetLogo: {
  //   position: 'fixed',
  //   left: '2rem',
  //   top: '2rem',
  // },
  ok: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  email: {
    padding: '2px',
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400"
  },
  visibleIcon: {
    color: 'grey',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  signup: {
    color: "#D52F37"
  },
  otpalert: {
    borderRadius: "5px",
    padding: "0px",
    marginTop: '10px',
  },
  resendotpbutton: {
    color: "#D52F37",
    textAlign: 'center',
    fontSize: "14px",
    width: "100px",
    height: "34px",
    marginTop: "2px",
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    borderRadius: "5px",
    padding: "5px",
  },
  login: {
    marginBottom: theme.spacing(2),
  },
  // form: {
  //   paddingBottom: '2rem',
  //   paddingTop: '2rem',
  // },
  resendotp_text: {
    color: "#02AC19",
    fontSize: "12px"
  },
  container: {
    maxWidth: '1536px',
    '@media (min-width: 601px)': {
      padding: '0px 2rem',
    },

    '@media (min-width: 1201px)': {
      padding: ' 0px 2rem',
    },
    '@media (max-width: 600px)': {
      padding: '0px 1rem',
    },
  },
  carouselContainer: {
    backgroundColor: 'transparent',
    maxWidth: '100%',
    margin: 'auto',
  },
  whiteBorder:{
    background:"whitesmoke",
    marginTop:'20px',
    height:"1px",
    padding:'1px',
    borderRadius:'5px',
    marginLeft:'15px'
  }
}));
