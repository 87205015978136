import React from 'react';
// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const adminNavConfig = [
  {
    title: 'dashboard',
    path: '/app/dashboard',
    icon: getIcon('akar-icons:dashboard'),
  },
  {
    title: 'users',
    path: '/app/users',
    icon: getIcon('ph:users-three-bold'),
    children: [
      { title: 'clients', path: '/app/client-users' },
      { title: 'individual', path: '/app/individual-users' },
    ],
  },
  {
    title: 'courses',
    path: '/app/course',
    icon: getIcon('bx:book-reader'),
  },
  {
    title: 'productsPlans',
    path: '/app/subscription',
    icon: getIcon('ic:outline-sell'),
  },
  {
    title: 'reports',
    path: '/app/reports',
    icon: getIcon('bi:bar-chart'),
    children: [
      { title: 'trialUsers', path: '/app/trial-users' },
      { title: 'subscriptions', path: '/app/subscribed-users-new' },
      {
        title: 'User Course Progress',
        path: '/app/course-progress',
      },
    ],
  },
  {
    title: 'tickets',
    path: '/app/feedback',
    icon: getIcon('fluent:person-feedback-24-regular'),
  },
  // {
  //   title: 'Question Book',
  //   path: '/app/question-book',
  //   icon: getIcon('fluent:book-question-mark-rtl-24-regular'),
  // },
];

const clientNavConfig = [
  {
    title: 'dashboard',
    path: '/app/dashboard',
    icon: getIcon('akar-icons:dashboard'),
  },
  {
    title: 'users',
    path: '/app/users',
    icon: getIcon('ph:users-three-bold'),
  },
  {
    title: 'courses',
    path: '/app/course',
    icon: getIcon('bx:book-reader'),
  },
  {
    title: 'analytics',
    path: '/app/analytics',
    icon: getIcon('bi:bar-chart'),
  },
];

export default { adminNavConfig, clientNavConfig };
