import i18n from 'i18next';
import client from '../apiClient';

function getCourseList() {
  return client.get('/user/enroll/course', {
    headers: {
      'Accept-Language': i18n.language
    }
  });
}

const getSubscriptionList = () => client.get('/individual/user/subscription',{headers: {
  'Accept-Language': i18n.language
}});

const getCourseModulesAndSubmodules = (courseId, planId) =>
  client.get(`/user/module/submodule/topics?courseId=${courseId}&&planId=${planId}`,{headers: {
    'Accept-Language': i18n.language
  }});

const getUserLastCompletedDetails = (courseId) => client.get(`/user/lastCompletedScreen?courseId=${courseId}`);

const getWebsiteIndividualCourses = (frequency) => client.get(`/website/course/individual?frequency=${frequency}`);

const getWebsiteBundleCourses = (frequency) => client.get(`/website/course/bundle?frequency=${frequency}`);

export default {
  getCourseList,
  getSubscriptionList,
  getCourseModulesAndSubmodules,
  getUserLastCompletedDetails,
  getWebsiteIndividualCourses,
  getWebsiteBundleCourses,
};
