import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Tooltip, Link, Breadcrumbs, Grid, Box, Button, IconButton, TextField } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/material/Autocomplete';
import * as Yup from 'yup';
import Page from '../../../components/Page';
import ErrorFocus from '../../../components/ErrorFocus/ErrorFocus';
import PageHeader from '../../../components/PageHeader';
import Reports from '../../../services/report/reports';
import BasicModal from '../../../components/modal/BasicModel';
import SnackBar from '../../../components/snackbar/snackbar';

export default function Feedback(props) {
  const classes = useStyles();
  const [feedbackList, setFeedbackList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('ticket'); 

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchKey, setSearchKey] = useState('');
  const [sortKey, setSortKey] = useState('createDateTime');
  const [sortOrder, setSortOrder] = useState('desc');
  const [startDate, setStartDate] = useState(new Date(Date.now() - 86400000));
  const [endDate, setEndDate] = useState(new Date());
  const [formLoading, setFormLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [ticketStatus, setTicketStatus] = useState([]);
  // const [agent, setAgent] = useState([]);
  const [priority, setPriority] = useState([]);
  const [ticketType, setTicketType] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [ticketId, setTicketId] = useState(null);

  const agentList = [
    { code: 'MANI', description: 'Mani' },
    { code: 'RADHIKA', description: 'Radhika' },
    { code: 'SUNIL', description: 'Sunil' },
    { code: 'RAJU', description: 'Raju' },
    { code: 'SAGAR', description: 'Sagar' },
    { code: 'SWAPNA', description: 'Swapna' },
    { code: 'PAVAN', description: 'Pavan' },
    { code: 'HARSHITHA', description: 'Harshitha' },
  ];

  const [formValues, setFormVales] = useState({
    ticketType: '',
    ticketStatus: '',
    agent: '',
    priority: '',
  });

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start > 0 && end > 0 && dates != null) {
      getFeedbackListByDateRange(moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD'), 'feedback');
    }
  };

  const getFeedbackList = async (pageNum, rows, sortOrd, sortCol, searchWord) => {
    setFeedbackList([]);
    setLoading(true);
    try {
      const response = await Reports.getFeedbackList(pageNum, rows, sortOrd, sortCol, searchWord);
      if (response.ok) {
        setFeedbackList(response.data.records);
        setPageCount(response?.data?.metadata?.totalRecordCount);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFeedbackListByDateRange = async (fromDate, toDate) => {
    setFeedbackList([]);
    setLoading(true);
    try {
      const response = await Reports.getFeedbackListBetweenDates(fromDate, toDate);
      if (response.ok) {
        setFeedbackList(response.data.records);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitForm = async (values) => {
    setFormLoading(true);
    const formData = {
      ticketType: values.ticketType.code,
      ticketStatus: values.ticketStatus.code,
      priority: values.priority.code,
      agent: values.agent.description,
    };
    try {
      const response = await Reports.putFeedbackList(ticketId, formData);
      if (response.ok) {
        getFeedbackList(page, rowsPerPage, sortOrder, sortKey, searchKey);
        setOpenModal(false);
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
    setFormLoading(false);
  };

  const getLookUpDetails = async () => {
    const key = ['ticketStatus', 'tickePriority', 'ticketType'];
    const result = await Reports.getLookUpData(key);
    if (result.ok) {
      setTicketStatus(result.data.ticketStatus);
      setPriority(result.data.tickePriority);
      setTicketType(result.data.ticketType);
    }
  };

  useEffect(() => {
    setLoading(true);
    getFeedbackList(page, rowsPerPage, sortOrder, sortKey, searchKey);
    getLookUpDetails();
  }, [props.getTrigerData]);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      sx={{
        width: '200px',
        padding: '4px 4px',
        color: 'black',
        border: '1px solid black',
        fontSize: '0.9rem',
        fontWeight: '500',
        '&:hover': {
          border: '1px solid black',
          background: 'unset',
        },
      }}
      className="example-custom-input"
      onClick={onClick}
      ref={ref}
      variant="outlined"
    >
      {value}
    </Button>
  ));

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: true,
        sort: false,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography className={classes.name}>{value}</Typography>;
        },
      },
    },
    {
      name: 'ticketId',
      label: t('ticketId'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value.length >= 20 ? (
                <Tooltip title={value} classes={{ arrow: classes.arrowName, tooltip: classes.tooltipName }}>
                  <Typography className={classes.name}>{value}</Typography>
                </Tooltip>
              ) : (
                <Typography className={classes.name}>{value}</Typography>
              )}
            </>
          );
        },
      },
    },
    {
      name: 'ticketType',
      label: t('ticketType'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value.length >= 20 ? (
                <Tooltip title={value} classes={{ arrow: classes.arrowName, tooltip: classes.tooltipName }}>
                  <Typography className={classes.name}>{value.description}</Typography>
                </Tooltip>
              ) : (
                <Typography className={classes.name}>{value.description}</Typography>
              )}
            </>
          );
        },
      },
    },
    {
      name: 'priority',
      label: t('priority'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <Typography className={classes.name}>{value.description}</Typography> : null;
        },
      },
    },
    {
      name: 'agent',
      label: t('agent'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <Typography className={classes.name}>{value}</Typography> : null;
        },
      },
    },
    {
      name: 'email',
      label: t('email'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value.length >= 20 ? (
                <Tooltip title={value} classes={{ arrow: classes.arrowName, tooltip: classes.tooltipName }}>
                  <Typography className={classes.name}>{value}</Typography>
                </Tooltip>
              ) : (
                <Typography className={classes.name}>{value}</Typography>
              )}
            </>
          );
        },
      },
    },
    {
      name: 'createDateTime',
      label: t('createdDate'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography className={classes.name}>{moment(value).format('MM-DD-YYYY')}</Typography>;
        },
      },
    },
    {
      name: 'completedDateTime',
      label: t('completedDate'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography className={classes.name}>{value && moment(value).format('MM-DD-YYYY')}</Typography>;
        },
      },
    },

    {
      name: 'image',
      label: t('image'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Link href={value} target="blank" className={classes.imagelink}>
              {value && 'View'}
            </Link>
          );
        },
      },
    },

    {
      name: 'ticketStatus',
      label: t('status'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let colors = '';
          const Capitalize = value.description;

          if (value.description === 'Open') {
            colors = 'green';
          } else if (value.description === 'Closed') {
            colors = 'red';
          } else {
            colors = 'gray';
          }
          return (
            <>
              {
                <Typography
                  sx={{
                    fontWeight: '400',
                    lineHeight: 'unset',
                    backgroundColor: colors,
                    textAlign: 'center',
                    borderRadius: '24px',
                    minWidth: '91px',
                    padding: '3px 0px',
                    fontSize: '0.9rem',
                    color: 'white',
                  }}
                >
                  {value.description}
                </Typography>
              }
            </>
          );
        },
      },
    },

    {
      name: '',
      label: t('action'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log(tableMeta);
          return (
            <div>
              <IconButton
                onClick={() => {
                  console.log(tableMeta);
                  setFormVales({
                    ticketType: tableMeta.rowData[2],
                    ticketStatus: tableMeta.rowData[9],
                    agent: { code: tableMeta?.rowData[4], description: tableMeta.rowData[4] },
                    priority: tableMeta.rowData[3],
                  });
                  setTicketId(tableMeta.rowData[0]);
                  setOpenModal(true);
                }}
              >
                <EditIcon fontSize="small" style={{ color: '#BCBCBC' }} />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const Breadcrumb = () => (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      <Typography
        sx={{ textDecoration: 'none' }}
        variant="body2"
        color="primary"
        component={RouterLink}
        to="/app/dashboard"
      >
        {t('dashboard')}
      </Typography>
      <Typography variant="body2" color="textPrimary">
        {t('tickets')}
      </Typography>
    </Breadcrumbs>
  );

  return (
    <Page title={'feedback'}>
      <PageHeader pageTitle={t('tickets')} breadcrumbs={<Breadcrumb />} />
      <Grid container>
        <Grid item xs="12" className={classes.gridAlign}>
          <Box className={classes.calenderStyle}>
            <Box sx={{ width: '100%', marginRight: '1rem' }}>
              <Typography variant="h6" sx={{ width: '100%', fontWeight: ' 500', marginTop: '3px' }} gutterBottom>
                {t('selectDates')}
              </Typography>
            </Box>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              customInput={<ExampleCustomInput />}
              todayButton="Today"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </Box>
        </Grid>
      </Grid>
      <MUIDataTable
        data={feedbackList}
        columns={columns}
        options={{
          pagination: true,
          filter: false,
          responsive: 'scroll',
          print: false,
          jumpToPage: true,
          download: true,
          page,
          search: true,
          count: pageCount,
          serverSide: true,
          rowsPerPage,
          rowsPerPageOptions: [10, 25, 50, 75, 100],
          selectableRows: false,

          textLabels: {
            body: {
              noMatch: loading ? t('loading') : t('sorry'),
            },
            pagination: {
              jumpToPage: t('jumpToPage'),
              rowsPerPage: t('rowsPerPage')
            }
          },
          onTableChange: (action, tableState) => {
            if (action === 'changeRowsPerPage') {
              setRowsPerPage(tableState.rowsPerPage);
              setFeedbackList([]);
              setPage(tableState.page);
              setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
              setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
              setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
              getFeedbackList(
                tableState.page + 1,
                tableState.rowsPerPage,
                tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                tableState.searchText !== null ? tableState.searchText : ''
              );
            } else if (action === 'changePage') {
              setRowsPerPage(tableState.rowsPerPage);
              setFeedbackList([]);
              setPage(tableState.page);
              setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
              setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
              setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
              getFeedbackList(
                tableState.page + 1,
                tableState.rowsPerPage,
                tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                tableState.searchText !== null ? tableState.searchText : ''
              );
            } else if (action === 'search') {
              setRowsPerPage(tableState.rowsPerPage);
              setFeedbackList([]);
              setPage(tableState.page);
              setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
              setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
              setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
              getFeedbackList(
                tableState.page + 1,
                tableState.rowsPerPage,
                tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                tableState.searchText !== null ? tableState.searchText : ''
              );
            } else if (action === 'sort') {
              setRowsPerPage(tableState.rowsPerPage);
              setFeedbackList([]);
              setPage(tableState.page);
              setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
              setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
              setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
              getFeedbackList(
                tableState.page,
                tableState.rowsPerPage,
                tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                tableState.searchText !== null ? tableState.searchText : ''
              );
            }
          },
        }}
      />

      <BasicModal
        openModel={openModal}
        title={'Edit Ticket'}
        closeModel={() => setOpenModal(false)}
        children={
          <Box mt={'1rem'}>
            <Formik
              initialValues={formValues}
              validationSchema={Yup.object().shape({
                ticketType: Yup.string().required('Required').nullable(),
                ticketStatus: Yup.string().required('Required').nullable(),
                agent: Yup.string().required('Required').nullable(),
                priority: Yup.string().required('Required').nullable(),
              })}
              onSubmit={(values) => handleSubmitForm(values)}
            >
              {({ errors, handleBlur, handleChange, values, touched, setFieldValue }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={6} sm={6}>
                      <Autocomplete
                        options={ticketType}
                        filterSelectedOptions
                        disableClearable
                        autoHighlight
                        getOptionLabel={(option) => option.description || ''}
                        value={values.ticketType}
                        onChange={(e, value) => {
                          setFieldValue('ticketType', value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Ticket Type*"
                            variant="outlined"
                            name="ticketType"
                            fullWidth
                            error={Boolean(touched.ticketType && errors.ticketType)}
                            helperText={touched.ticketType && errors.ticketType}
                          />
                        )}
                      />
                      <ErrorFocus />
                    </Grid>
                    <Grid item xs={12} sx={6} sm={6}>
                      <Autocomplete
                        options={priority}
                        filterSelectedOptions
                        disableClearable
                        autoHighlight
                        getOptionLabel={(option) => option.description || ''}
                        value={values.priority}
                        onChange={(e, value) => {
                          setFieldValue('priority', value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Priority*"
                            variant="outlined"
                            name="priority"
                            fullWidth
                            error={Boolean(touched.priority && errors.priority)}
                            helperText={touched.priority && errors.priority}
                          />
                        )}
                      />
                      <ErrorFocus />
                    </Grid>
                    <Grid item xs={12} sx={6} sm={6}>
                      <Autocomplete
                        options={agentList}
                        filterSelectedOptions
                        disableClearable
                        autoHighlight
                        getOptionLabel={(option) => option.description || ''}
                        value={values.agent}
                        onChange={(e, value) => {
                          setFieldValue('agent', value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Agent*"
                            variant="outlined"
                            name="agent"
                            fullWidth
                            error={Boolean(touched.agent && errors.agent)}
                            helperText={touched.agent && errors.agent}
                          />
                        )}
                      />
                      <ErrorFocus />
                    </Grid>
                    <Grid item xs={12} sx={6} sm={6}>
                      <Autocomplete
                        options={ticketStatus}
                        filterSelectedOptions
                        disableClearable
                        autoHighlight
                        getOptionLabel={(option) => option.description || ''}
                        value={values.ticketStatus}
                        onChange={(e, value) => {
                          setFieldValue('ticketStatus', value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status*"
                            variant="outlined"
                            name="ticketStatus"
                            fullWidth
                            error={Boolean(touched.ticketStatus && errors.ticketStatus)}
                            helperText={touched.ticketStatus && errors.ticketStatus}
                          />
                        )}
                      />
                      <ErrorFocus />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <LoadingButton variant="contained" color="primary" loading={formLoading} type="submit" fullWidth>
                        Update
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        }
      />
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </Page>
  );
}
const useStyles = makeStyles((theme) => ({
  name: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '160px',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
    },
  },
  imagelink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tooltip: {
    backgroundColor: '#FE7000',
    color: '#FFFFFF',
  },
  arrowName: {
    [theme.breakpoints.up('md')]: {
      color: 'unset',
      display: 'none',
    },
  },
  tooltipName: {
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'white',
      color: 'black',
      border: '1px solid gray',
    },
  },
  calenderStyle: {
    position: 'absolute',
    zIndex: '111',
    display: 'flex',
    justifyContent: 'end',
  },
  gridAlign: {
    display: 'flex',
    justifyContent: 'end',
    margin: '0rem 0rem 5rem 0rem',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
    },
  },
}));
