import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import { useProSidebar } from 'react-pro-sidebar';
// components
import Iconify from '../../components/Iconify';

import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
import LanguageSwitcher from '../../website/pages/LanguageSwitcher';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP - 24,
    padding: theme.spacing(0, 5),
  },
}));

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: '0px 3px 6px #00000029',
  // backdropFilter: 'blur(6px)',
  // WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  // backgroundColor: alpha(theme.palette.background.default, 0.72),
  background: '#fdfdfdf2',
  [theme.breakpoints.up('lg')]: {
    // width: `calc(100% - ${collapsed ? 70 : 270}px)`,
    width: '100%',
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { collapsed } = useProSidebar();

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
        {process.env.REACT_APP_ENV === 'dev' &&  <LanguageSwitcher />}
        <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
