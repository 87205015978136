import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { BroadcastChannel } from 'broadcast-channel';
// components
import MenuPopover from '../../components/MenuPopover';
import { logoutSuccess, guideHandler } from '../../store/reducer';
import loginServices from '../../services/loginServices';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  // {
  //   label: 'Home',
  //   icon: 'eva:home-fill',
  //   linkTo: '/',
  // },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '/app/account',
  },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  //   linkTo: '#',
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation('translation'); 
  const { pathname } = useLocation();
  const userInfo = useSelector((state) => state.userInfo);
  const userRole = useSelector((state) => state.userInfo && state.userInfo.role);
  const [open, setOpen] = useState(null);
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.isLogin);
  const [show, setShow] = useState(false);
  const logoutChannel = new BroadcastChannel('logout');

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const userName = useSelector((state) => state.userInfo.name);
  // console.log('userName', userName);

  // userInfo && userInfo.name;

  const handleClose = () => {
    setOpen(null);
  };

  // logout();
  const handleLogout = () => {
    logoutChannel.postMessage('Logout');
    dispatch(logoutSuccess());
    navigate('/login');
    loginServices.logout();
  };

  const guidHandler = () => {
    dispatch(guideHandler(true));
    handleClose();
  };

  const handleGotoDashboard = () => {
    if (isLogin) {
      if (isLogin && (userRole === 'SUPER_ADMIN' || userRole === 'CLIENT_ADMIN')) {
        navigate('/app/dashboard');
        // window.location.href = '/app/dashboard';
      } else if (isLogin && (userRole === 'CONTENT_WRITER' || userRole === 'AUTH_USER')) {
        navigate('/app/course');
        // window.location.href = '/app/course';
      } else {
        navigate('/auth/my-courses');
      }
    }
    setOpen(null);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar() {
    const fullName = userInfo.name.trim();
    return {
      sx: {
        // bgcolor: stringToColor(name),
        fontSize: '1.4rem',
        fontWeight: '600',
      },
      children: `${fullName.charAt(0).toUpperCase()}`,
    };
  }

  useEffect(() => {
    if (['/app/course-details', '/auth/course-details'].includes(pathname)) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [pathname]);
  const translatedMenuOptions = [
    {
      label: t('profile'),
      icon: 'eva:person-fill',
      linkTo: '/app/account',
    },
  ];

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {userInfo && userInfo.profileImg !== null && userInfo.profileImg !== '' ? (
          <Avatar src={userInfo && userInfo.profileImg} alt="photoURL" />
        ) : (
          <Avatar {...stringAvatar()} />
        )}
      </IconButton>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, paddingLeft: '8px !important' }} pl={2}>
          <Typography variant="subtitle2" noWrap>
            {userInfo && userInfo.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userInfo && userInfo.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleGotoDashboard} sx={{ ml: 1, mt: 1, mr: 1 }}>
        {t('dashboard')}
        </MenuItem>

        <Stack sx={{ p: 1 }}>
        {translatedMenuOptions.map((option) => (
            <MenuItem
              key={option.label}
              to={userRole === 'USER_DTC' ? '/auth/account' : option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {option.label}
            </MenuItem>
          ))}

          {userRole === 'USER_DTC' && (
            <MenuItem to={'/auth/subscriptions'} component={RouterLink} onClick={handleClose} sx={{ mt: 1 }}>
              Subscriptions
            </MenuItem>
          )}
        </Stack>

        {show && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <MenuItem onClick={guidHandler} sx={{ m: 1 }}>
              Take Tour
            </MenuItem>
          </>
        )}
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
        {t('logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
