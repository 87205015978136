import React,{useState,useEffect} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

import DialogModal from '../../../components/modal/DialogModal';


const TemplatesDetails = ({ open, modelClose ,title,templates,openModalIntro,setOpenModalIntro,getTemplate,onCallBack,onCallBackNew}) => {

    const handleDetails = (details) =>{
        onCallBack(details)
        setOpenModalIntro(true)

    }
    const handleDelete = (details) =>{
        onCallBackNew(details)
    }
    return (
        <DialogModal open={open} handleClose={modelClose} title={title}>
            <List>
                {templates.map((template, index) => (
                    <ListItem key={template.id}>
                        <img src={template.url} alt={template.name} width={110} height={70} style={{ marginRight: '10px',padding:'10px' }} />
                        <span>{template.name}</span>
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="edit" style={{margin:'5px'}}
                            >
                                <EditIcon onClick={()=>{
                                handleDetails(template)
                              }} />
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" onClick={()=>handleDelete(template.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </DialogModal>
    );
};

export default TemplatesDetails;
