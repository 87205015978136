import React, { useState, useEffect } from 'react';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { Avatar, Badge, Grid, FormControl, FormHelperText, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DropzoneArea } from 'material-ui-dropzone';
import { Form, Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { LoadingButton } from '@mui/lab';

import DialogModal from '../../../components/modal/DialogModal';
import ErrorFocus from '../../../components/ErrorFocus/ErrorFocus';
import LinearProgressBar from '../../../components/progress/LinearProgressBar';



const CreateIntroTemplate = ({ title, open, modelClose, submitForm, loading, data, formType, deleteVideo, progress,details }) => {
    const classes = useStyles();
    const user = useSelector((state) => state.userInfo);
    const [initialValues, setInitialValues] = useState({
        templatename:" ",
        Image: null,
        ImagePreview: null,
        // authors: formType==='CREATE'? [user.firstName + ' ' + user.lastName]:[],
    });


    useEffect(()=>{
        setInitialValues({
            templatename: details.name,
            Image: details.url,
            ImagePreview: details.url,
            // authors: formType==='CREATE'? [user.firstName + ' ' + user.lastName]:[],
        });  
    },[details])
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const imageWidthAndHeight = (provideFile) => {
        // take the given file (which should be an image) and return the width and height\
        if (provideFile) {
            const imgDimensions = { width: null, height: null };
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(provideFile);
                reader.onload = function () {
                    const img = new Image();
                    img.src = reader.result;
                    img.onload = function () {
                        imgDimensions.width = img.width;
                        imgDimensions.height = img.height;
                        resolve(imgDimensions);
                    };
                };
            });
        }
    };

    return (
        <DialogModal open={open} handleClose={modelClose} title={title}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    templatename: Yup.string()
                        .trim()
                        .required('Required')
                        .nullable()
                        .min(3, 'Template Name contains at least 3 characters'),


                    Image: Yup.mixed().when('ImagePreview', {
                        is: null,
                        then: Yup.mixed()
                            .nullable()
                            .required('Required')
                        //     .test(
                        //         'aspectRatio',
                        //         'image dimensions should be at least 360X200 ',
                        //         (value) => value && imageWidthAndHeight(value).then((res) => res.width >= 360 && res.height >= 200)
                        //     )
                        //     .test('fileSize', 'Maximum file size is 2MB', (value) =>
                        //         value && typeof value === 'object' ? value.size < 2097152 : 0 < 2097152
                        //     ),
                        // otherwise: Yup.mixed()
                        //     .nullable()
                        //     .test('aspectRatio', 'image dimensions should be at least 360X200 ', (value) => {
                        //         if (value) {
                        //             return imageWidthAndHeight(value).then((res) => res.width >= 360 && res.height >= 200);
                        //         }
                        //         return 1 < 2;
                        //     }),
                    }),
                })}
                onSubmit={(values) => submitForm(values,details?'UPDATE':'CREATE')}
            >
                {({ values, touched, errors, handleBlur, handleChange, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={3}>

                            <Grid item xs={12} sm={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={Boolean(touched.templatename && errors.templatename)}
                                            fullWidth
                                            helperText={touched.templatename && errors.templatename}
                                            label="Template Name*"
                                            name="templatename"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.templatename}
                                            variant="outlined"
                                            inputProps={{ maxLength: 128 }}
                                            id='templateName'
                                        />
                                        <ErrorFocus />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        {values.ImagePreview === null ? (
                                            <FormControl
                                                required
                                                component="fieldset"
                                                color="primary"
                                                variant="outlined"
                                                fullWidth
                                                name="Image"
                                                value={values.Image}
                                            >
                                                <Typography variant="subtitle1">Image*</Typography>

                                                <DropzoneArea
                                                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                                    showPreviews={false}
                                                    dropzoneText="Drag and Drop Image or Browse File"
                                                    showPreviewsInDropzone={false}
                                                    maxFileSize={300000000}
                                                    filesLimit={1}
                                                    showAlerts={false}
                                                    value={values.Image}
                                                    // onChange={(file) => {
                                                    //     setFieldValue('Image', file[0]);
                                                    //     if (file[0]?.size < 2097152) {
                                                    //             const url = URL.createObjectURL(file[0]);
                                                    //             setFieldValue('ImagePreview', url);
                                                    //         // imageWidthAndHeight(file[0]).then((res) => {
                                                    //         //     if (res.width >= 360 && res.height >= 200) {
                                                    //         //         const url = URL.createObjectURL(file[0]);
                                                    //         //         setFieldValue('ImagePreview', url);
                                                    //         //     }
                                                    //         // });
                                                    //     }
                                                    // }}
                                                    onChange={(file) => {
                                                        setFieldValue('Image', file[0]);
                                                        setFieldValue('ImageName', file[0]?.name);
                                                        if (file[0]?.size <= 2097152) {
                                                            const url = URL.createObjectURL(file[0]);
                                                            setFieldValue('ImagePreview', url);
                                                        }
                                                       
                                                    }}
                                                    useChipsForPreview
                                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                    showFileNamesInPreview
                                                />
                                                <FormHelperText style={{ color: '#F44336' }}>
                                                    {(errors.Image || touched.Image) && errors.Image}
                                                </FormHelperText>
                                            </FormControl>
                                        ) : (
                                            <div className={classes.imgPreviewRoot}>
                                                <Typography variant="subtitle1">Image</Typography>
                                                <Badge
                                                    badgeContent={
                                                        <CloseIcon
                                                            onClick={() => {
                                                                setFieldValue('ImagePreview', null);
                                                                setFieldValue('Image', null);
                                                            }}
                                                            className={classes.badgeAlign}
                                                        />
                                                    }
                                                >
                                                    <Avatar
                                                        variant="rounded"
                                                        src={values.ImagePreview}
                                                        className={values.ImagePreview !== null && classes.fileImgSIze}
                                                    />
                                                </Badge>
                                                <Typography sx={{ fontSize: '0.75rem !important' }} color="red">
                                                    {(errors.Image || touched.Image) && errors.Image}
                                                </Typography>
                                            </div>
                                        )}
                                    </Grid>


                                </Grid>
                            </Grid>


                            { // eslint-disable-next-line prefer-template     
                                (user.role === 'SUPER_ADMIN' || user.role === 'CONTENT_WRITER'|| (values.authors).includes(user.firstName + ' ' + user.lastName)) && <Grid item sm={12}>
                                    {loading && values.videoFile ? (
                                        <LinearProgressBar value={progress} />
                                    ) : (
                                        <LoadingButton type="submit" variant="contained" color="primary" id="templateButton" fullWidth loading={loading}>
                                            {formType === 'CREATE' ? 'Create' : 'Update'}
                                        </LoadingButton>
                                    )}
                                </Grid>}
                        </Grid>
                    </Form>
                )}
            </Formik>
        </DialogModal>


    );
};

const useStyles = makeStyles((theme) => ({
    imgPreviewRoot: {
        borderRadius: '10px',
        padding: '0.8rem;',
    },
    fileImgSIze: {
        width: '100%',
        height: '120px',
        objectFit: 'cover',
        objectPosition: 'center',
        border: '1px solid #fff',
        borderRadius: '5px',
        boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
    },
    badgeAlign: {
        boxShadow: '0 2px 8px -5px #ff0000',
        color: '#FF0000',
        fontSize: '1.2rem',
        backgroundColor: '#fff',
        padding: '2px',
        borderRadius: '10px',
        cursor: 'pointer',
    },
    deleteLabel: {
        width: 'max-content',
        cursor: 'pointer',
    },
}));

export default CreateIntroTemplate;
