import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, Container, Button, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GridOnIcon from '@mui/icons-material/GridOn';
import ViewListIcon from '@mui/icons-material/ViewList';
import { makeStyles } from '@mui/styles';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Page from '../../../components/Page';
import UserCard from '../../../components/cards/UserCard';
import PageHeader from '../../../components/PageHeader';
import courseApi from '../../../services/users/courseApi';
import CardSkeleton from '../../../components/Skeleton/cardSkeleton';
import Background from '../../../assets/logo/myCourseBackground.svg';
import ChatDialog from './ChatDialog';

export default function CourseList(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [courseGroups, setCourseGroup] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const userRole = useSelector((state) => state.userInfo && state.userInfo.role);
  localStorage.removeItem('expandedItem');
  localStorage.removeItem('subModuleExpandedItem');
  const style = {
    marginTop: '14px',
    paddingLeft: '24px !important',
    paddingRight: '24px !important',
  };

  useEffect(() => {
    getCourserList();
    const script = document.getElementById('zsiqscript');
    console.log(script);
    if (script) {
      script.parentNode.removeChild(script);
    }
  }, []);

  const getCourserList = async () => {
    const response = await courseApi.getCourseList();
    if (response.ok) {
      console.log(response.data);
      setCourseList(response.data);
      setLoading(false);

      const individualCourses = response.data.filter((item) => item.isBundle === false);
      console.log('individual ', individualCourses);

      const bundleCourses = response.data.filter((item) => item.isBundle);
      console.log('bundleCourses ', bundleCourses);

      const grouped = Array.from(
        bundleCourses
          .reduce((m, o) => m.set(o.subscriptionPlanId, (m.get(o.subscriptionPlanId) || []).concat(o)), new Map())
          .values()
      );
      console.log([[...individualCourses], ...grouped]);
      setCourseGroup([[...individualCourses], ...grouped]);

      if (userRole === 'USER_DTC' && response.data?.length === 0) {
        navigate('/auth/subscribe');
      }
    }
  };

  const handleCardClickItem = (data) => {
    if (userRole === 'USER_DTC') {
      navigate('/auth/course-details', { state: data });
    } else {
      navigate('/app/course-details', { state: data });
    }
  };

  const handleChatOpen = () => {
    setIsChatOpen(true);
  };

  const handleChatClose = () => {
    setIsChatOpen(false);
  };

  function LinearProgressWithLabel({ value }) {
    return (
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px !important' }} mb={0.5}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" value={value} />
          </Box>
          <Box sx={{ width: 'max-content' }}>
            <Typography variant="body2" color="gray" sx={{ fontSize: '0.7rem' }}>
              {value}%
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" color={'primary'} sx={{ fontSize: '0.7rem', marginTop: '-8px' }}>
          Completed
        </Typography>
      </Box>
    );
  }

  const ButtonGrp = () => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
      <IconButton>
        <SearchIcon fontSize="medium" style={{ color: '#BCBCBC' }} />
      </IconButton>
      <IconButton>
        <FilterAltIcon fontSize="medium" style={{ color: '#BCBCBC' }} />
      </IconButton>
      <IconButton>
        <GridOnIcon fontSize="medium" style={{ color: '#00B673' }} />
      </IconButton>
      <IconButton>
        <ViewListIcon fontSize="medium" style={{ color: '#BCBCBC' }} />
      </IconButton>
    </div>
  );

  return (
    <div>
      <Container className={classes.coursediv} maxWidth="xl">
        <Typography variant="h4" className={classes.title}>
        {t('my_courses')}
        </Typography>
      </Container>
      <Page title="Course-list" style={style}>
        <PageHeader pageTitle={"MycoursePageB2BUser"}  breadcrumbs={<ButtonGrp />} />
        <Container maxWidth={false} sx={{ padding: '0 !important' }}>
          <Grid container spacing={2}>
            {loading ? (
              <>
                {[1, 2, 3, 4].map((res) => (
                  <Grid item xs={12} sm={4} md={4} lg={3}>
                    <CardSkeleton />
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {userRole === 'AUTH_USER' &&
                  courseList.map((item) => {
                    return (
                      <>
                        <Grid item xs="12" sm="4" md="4" lg="3" xl="3">
                          <UserCard
                            handleCardClick={() => handleCardClickItem(item)}
                            image={item.thumbImage}
                            planStatus={item.planStatus}
                            title={item.title}
                            trial={item.isTrial}
                            subscribed={item.isSubscribed}
                            // description={item.description}
                            category={item.category}
                            logo={item.category.categoryImgUrl}
                            enrollDate={item.validFrom}
                            progress={<LinearProgressWithLabel value={item.completed} />}
                            data={item}
                          />
                        </Grid>
                      </>
                    );
                  })}

                {userRole === 'USER_DTC' &&
                  courseGroups.map((res, indx) => {
                    return (
                      <>
                        {res[indx]?.type === 'simulation' && <Grid item xs={12} sm={12} mb={res.length > 0 ? 4 : 0}>
                          <Typography variant="h5" gutterBottom>
                            {res[0]?.isBundle && res[0]?.subscriptionPlanName}
                            {res[indx]?.isBundle === false && 'Keyskillset Individual Courses'}
                          </Typography>
                          <Grid container spacing={2} mt={1}>
                            {res?.map((item) => (
                              <Grid item xs="12" sm="4" md="4" lg="3" xl="3">
                                <UserCard
                                  handleCardClick={() => handleCardClickItem(item)}
                                  image={item.thumbImage}
                                  planStatus={item.planStatus}
                                  title={item.title}
                                  trial={item.isTrial}
                                  subscribed={item.isSubscribed}
                                  // description={item.description}
                                  category={item.category}
                                  logo={item.category.categoryImgUrl}
                                  enrollDate={item.validFrom}
                                  progress={<LinearProgressWithLabel value={item.completed} />}
                                  data={item}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>}
                      </>
                    );
                  })}

                {courseList.length === 0 && (
                  <Grid item xs={12}>
                    <Box mt={4}>
                      <Typography variant="h6" textAlign={'center'}>
                        No Data
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Container>
        {process.env.REACT_APP_ENV === 'dev' && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleChatOpen}
            style={{ position: 'fixed', bottom: 20, right: 20 }}
          >
            Chat
          </Button>
         )} 
        <ChatDialog open={isChatOpen} onClose={handleChatClose} />
      </Page>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  coursediv: {
    height: '10rem',
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    marginTop: '4rem',
  },
  title: {
    padding: '4rem 0rem 0rem 0rem',
  },
}));
