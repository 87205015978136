/* eslint-disable */
import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { Typography, Chip, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

const AllTrialUsers = ({ allUserData, loading, getAllTrialUsersData, pageCount }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const { t } = useTranslation('report'); 

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchKey, setSearchKey] = useState('');
  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [filterValue, setFilterValue] = useState('');

  const columns = [
    {
      name: 'coustomer',
      label: t('customer'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <>
              {value.length >= 19 ? (
                <Tooltip title={value} classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} arrow>
                  <Typography className={classes.name}>{value}</Typography>
                </Tooltip>
              ) : (
                <Typography className={classes.name}>{value}</Typography>
              )}
            </>
          ) : (
            ''
          );
        },
      },
    },
    {
      name: 'status',
      label: t('status'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let colors = '';
          const Capitalize = value.toLowerCase();

          if (value === 'ACTIVE') {
            colors = 'green';
          } else if (value === 'EXPIRED') {
            colors = 'red';
          } else {
            colors = 'gray';
          }
          return value ? (
            <>
              {
                <Typography
                  sx={{
                    width: 'min-content',
                    fontWeight: '400',
                    lineHeight: 'unset',
                    backgroundColor: colors,
                    textAlign: 'center',
                    borderRadius: '4px',
                    padding: '2px 8px',
                    fontSize: '0.9rem',
                    color: 'white',
                  }}
                >
                  {Capitalize.charAt(0).toUpperCase() + Capitalize.slice(1)}
                </Typography>
              }
            </>
          ) : (
            ''
          );
        },
      },
    },
    {
      name: 'planType',
      label: 'Plan Type',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'subscriptionPlanName',
      label: t('plan'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <>
              {value.length >= 19 ? (
                <>
                  <Tooltip title={value} classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} arrow>
                    <Typography className={classes.name}>{value}</Typography>
                  </Tooltip>
                  <Chip label={tableMeta.rowData[2]} size="small" />
                </>
              ) : (
                <>
                  <Typography className={classes.name}>{value}</Typography>
                  <Chip label={tableMeta.rowData[2]} size="small" />
                </>
              )}
            </>
          ) : (
            ''
          );
        },
      },
    },
    {
      name: 'courses',
      label: t('courses'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const datalength = value.map((item) => item.courseName).join(',');

          return value ? (
            <>
              {datalength.length >= 19 ? (
                <Tooltip
                  title={value.map((item) => item.courseName).join(',')}
                  classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                  arrow
                >
                  <Typography className={classes.name}>{value.map((item) => item.courseName).join(',')}</Typography>
                </Tooltip>
              ) : (
                <Typography className={classes.name}>{value.map((item) => item.courseName).join(',')}</Typography>
              )}
            </>
          ) : (
            ''
          );
        },
      },
    },
    {
      name: 'startDate',
      label: t('currentPeriodStart'),
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <>
              <Typography className={classes.dateWidth}>{moment(value).format('ll')}</Typography>
            </>
          ) : (
            ''
          );
        },
      },
    },
    {
      name: 'endDate',
      label: t('currentPeriodEnd'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <>
              <Typography className={classes.dateWidth}>{moment(value).format('ll')}</Typography>
            </>
          ) : (
            ''
          );
        },
      },
    },
    {
      name: 'createDateTime',
      label: t('created'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <Typography className={classes.dateWidth}>{moment(value).format('lll')}</Typography> : '';
        },
      },
    },
  ];

  return (
    <div>
      <div style={{ marginTop: '1rem' }}>
        <ThemeProvider theme={customTheme}>
          <MUIDataTable
            data={allUserData}
            columns={columns}
            options={{
              responsive: 'scroll',
              pagination: true,
              filter: false,
              download: true,
              print: false,
              jumpToPage: true,
              page,
              search: true,
              viewColumns: false,
              count: pageCount,
              serverSide: true,
              rowsPerPage,
              rowsPerPageOptions: [10, 25, 50, 75, 100],
              selectableRows: false,
              textLabels: {
                body: {
                  noMatch: loading ? 'Loading...' : 'Sorry, there is no matching data to display',
                },
              },
              onTableChange: (action, tableState) => {
                console.log(tableState, 'tableState');
                if (action === 'changeRowsPerPage') {
                  setRowsPerPage(tableState.rowsPerPage);
                  setPage(tableState.page);
                  setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                  setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                  setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                  getAllTrialUsersData(
                    '',
                    tableState.page + 1,
                    tableState.rowsPerPage,
                    tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                    tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                    tableState.searchText !== null ? tableState.searchText : ''
                  );
                } else if (action === 'changePage') {
                  setRowsPerPage(tableState.rowsPerPage);
                  setPage(tableState.page);
                  setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                  setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                  setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                  getAllTrialUsersData(
                    '',
                    tableState.page + 1,
                    tableState.rowsPerPage,
                    tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                    tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                    tableState.searchText !== null ? tableState.searchText : ''
                  );
                } else if (action === 'search') {
                  setRowsPerPage(tableState.rowsPerPage);
                  setPage(tableState.page);
                  setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                  setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                  setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                  getAllTrialUsersData(
                    '',
                    tableState.page,
                    tableState.rowsPerPage,
                    tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                    tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                    tableState.searchText !== null ? tableState.searchText : ''
                  );
                } else if (action === 'sort') {
                  setRowsPerPage(tableState.rowsPerPage);
                  setPage(tableState.page);
                  setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                  setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                  setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                  getAllTrialUsersData(
                    '',
                    tableState.page,
                    tableState.rowsPerPage,
                    tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                    tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                    tableState.searchText !== null ? tableState.searchText : ''
                  );
                } else if (action === 'filterChange') {
                  setRowsPerPage(tableState.rowsPerPage);
                  setPage(tableState.page);
                  setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                  setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                  setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                  getAllTrialUsersData(
                    '',
                    tableState.page,
                    tableState.rowsPerPage,
                    tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                    tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                    tableState.searchText !== null ? tableState.searchText : ''
                    // `${tableState.filterList[2][0]},${tableState.filterList[3][0]},${
                    //   tableState.filterList[9][0] === 'Paid'
                    //     ? true
                    //     : tableState.filterList[9][0] === 'Free'
                    //     ? false
                    //     : tableState.filterList[9][0]
                    // },${tableState.filterList[10][0]}`
                  );
                }
              },
            }}
          />
        </ThemeProvider>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  dateWidth: {
    width: '110px',
    fontSize: '0.95rem',
  },
  name: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '160px',
    [theme.breakpoints.down('md')]: {
      overflow: 'hidden',
      maxWidth: 'unset',
      textOverflow: 'ellipsis',
      '&:hover': {
        maxWidth: 'unset',
        textOverflow: 'clip',
        whiteSpace: 'normal',
      },
    },
  },
  arrow: {
    [theme.breakpoints.up('md')]: {
      color: 'unset',
      display: 'none',
    },
  },
  tooltip: {
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'white',
      color: 'black',
      border: '1px solid gray',
    },
  },
}));

const customTheme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px 16px !important',
          fontFamily: 'Inter,SemiBold',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter,SemiBold',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontFamily: 'Inter,SemiBold',
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        sortActive: {
          fontFamily: 'Inter,SemiBold',
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        iconActive: {
          color: '#FE7000',
          '&:hover': {
            color: '#FE7000',
          },
        },
      },
    },
    MUIDataTableSearch: {
      styleOverrides: {
        clearIcon: {
          '&:hover': {
            color: '#FE7000',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          ':after': {
            borderBottom: '2px solid #FE7000',
          },
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        icon: {
          '&:hover': {
            color: '#FE7000',
          },
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        data: {
          fontFamily: 'Inter,SemiBold',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'Inter,SemiBold ! important',
        },
      },
    },
  },
});

export default AllTrialUsers;
