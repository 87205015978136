import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Button, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/Minimize';
import Draggable from 'react-draggable';
import adminServices from '../../../services/adminServices';

const ChatWindow = ({ open, onClose }) => {
  const [inputValue, setInputValue] = useState('');
  const [chats, setChats] = useState([]);
  const [isMinimized, setIsMinimized] = useState(false);
  const chatContainerRef = useRef(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleSendMessage = async () => {
    const userQuestion = inputValue.trim();
    console.log('userQuestion is..', userQuestion);
    
    if (userQuestion) {
      
      const newChat = { question: userQuestion, answer: '...' };
      setChats([...chats, newChat]);
      setInputValue('');
  
      try {
        
        const history = chats.map((chat) => [
          {
            role: 'user',
            parts: [{ text: chat.question }],
          },
          {
            role: 'model',
            parts: [{ text: chat.answer }],
          },
        ]).flat();
  
        history.push({
          role: 'user',
          parts: [{ text: userQuestion }],
        });
  
        const nextMsg = userQuestion;
  
        const responsePayload = {
          history,
          nextMsg,
        };
  
        console.log('Sending payload:', JSON.stringify(responsePayload, null, 2));
        const response = await adminServices.getChatResult(responsePayload);
        console.log('response from chatBot', response?.data?.responseString);
        const updatedChats = [...chats, { question: userQuestion, answer: response.data.responseString }];
        setChats(updatedChats);
      } catch (error) {
        console.error("There was an error fetching the chat response:", error.response?.data || error.message);
      }
    }
  };
  
  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleClearChat = () => {
    setChats([]);
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chats]);

  if (!open) return null;

  return (
    <Draggable>
      <Box
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          width: isMinimized ? '300px' : '400px',
          height: isMinimized ? '50px' : 'auto',
          maxHeight: '80vh',
          backgroundColor: 'white',
          boxShadow: 3,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          zIndex: 1000
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1, backgroundColor: '#3f51b5', color: 'white' }}>
          <Typography variant="h6">Chat</Typography>
          <Box>
            <IconButton size="small" onClick={handleMinimize} style={{ color: 'white' }}>
              <MinimizeIcon />
            </IconButton>
            <IconButton size="small" onClick={onClose} style={{ color: 'white' }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        {!isMinimized && (
          <Box ref={chatContainerRef} sx={{ padding: 2, flex: 1, overflowY: 'auto' }}>
            {chats.map((chat, index) => (
              <Box key={index} mb={2}>
                <Typography variant="body1"><strong>Q:</strong> {chat.question}</Typography>
                <Typography variant="body1"><strong>A:</strong> {chat.answer}</Typography>
              </Box>
            ))}
            <TextField
              fullWidth
              multiline
              rows={4}
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Type your message here..."
              variant="outlined"
              margin="normal"
            />
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Button variant="contained" color="primary" onClick={handleSendMessage}>
                Send
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleClearChat}>
                Clear Chat
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Draggable>
  );
};

export default ChatWindow;
