// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../../locales/en/translation.json';
import translationFR from '../../locales/fr/translation.json';
import translationES from '../../locales/es/translation.json';
import dashboardEN from '../../locales/en/dashboard.json';
import dashboardFR from '../../locales/fr/dashboard.json';
import dashboardES from '../../locales/es/dashboard.json';
import userEN from '../../locales/en/user.json';
import userFR from '../../locales/fr/user.json';
import userES from '../../locales/es/user.json';
import courseEN from '../../locales/en/course.json';
import courseFR from '../../locales/fr/course.json';
import courseES from '../../locales/es/course.json';
import productsPlansEN from '../../locales/en/productsPlans.json';
import productsPlansFR from '../../locales/fr/productsPlans.json';
import productsPlansES from '../../locales/es/productsPlans.json';
import reportEN from '../../locales/en/report.json';
import reportFR from '../../locales/fr/report.json';
import reportES from '../../locales/es/report.json';
import ticketEN from '../../locales/en/ticket.json';
import ticketFR from '../../locales/fr/ticket.json';
import ticketES from '../../locales/es/ticket.json';
import navEN from '../../locales/en/nav.json'
import navFR from '../../locales/fr/nav.json'
import navES from '../../locales/es/nav.json'

const resources = {
  en: {
    translation: translationEN,
    dashboard: dashboardEN,
    user: userEN,
    course: courseEN,
    productsPlans: productsPlansEN,
    report: reportEN,
    ticket: ticketEN,
    nav: navEN
  },
  fr: {
    translation: translationFR,
    dashboard: dashboardFR,
    user: userFR,
    course: courseFR,
    productsPlans: productsPlansFR,
    report: reportFR,
    ticket: ticketFR,
    nav: navFR
  },
  es: {
    translation: translationES,
    dashboard: dashboardES,
    user: userES,
    course: courseES,
    productsPlans: productsPlansES,
    report: reportES,
    ticket: ticketES,
    nav: navES
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false, // React already sanitizes the output
    },
  });

  console.log('i18n instance:', i18n);

export default i18n;
