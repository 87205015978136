import React, { useState, useEffect, useRef } from 'react';
import { List,Box, Typography, useTheme } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import Iconify from '../../components/Iconify';
import './scrollStyle.css';
import LottieLoading from '../../components/LottieLoading';

import simulationApi from '../../services/simulation';

const LinkBtn = React.forwardRef((props, ref) => {
  return <NavLink to={props.to} {...props} innerRef={ref} />;
});

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderBottom: '1px solid #BCBCBC',
    padding: 12,
  },
  skillIqTest: {
    padding: '4px 14px',
    borderRadius: '6px',
    margin: 'auto',
    border: '1px solid #FE7000',
    width: 'max-content',
    marginTop: '1rem',
    cursor: 'pointer',
  },
}));

const VideoTopicList = ({ topics, onClickCallBack, selectedIndex, submoduleId, location, onReferenceButtonClick, subModuleName,details }) => {
  // console.log(topics,"topics");

  // console.log(selectedIndex,"selectedIndexselectedIndex");
  
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.userInfo?.role);
  const [screens, setScreens] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  React.useMemo(()=>{
   setScreens(prevScreens => 
    prevScreens.map((screen, index) => index === details ? { ...screen, isCompleted: true } : screen
  ))

  //  setScreens(screens)
  },[details])

  useEffect( async () => {
    setIsLoading(true)
   
    const selectedTopic = topics[0]
    if(selectedTopic!==undefined){
      await getScreens(topics[0].id, "HOTKEYS")
      setIsLoading(false)
    }
   
  
  }, [topics]);
  
  const handleListItemClick = (event, index, item) => {   
    
    if (item?.actionType?.code === 'REFERENCE') {
      
      onReferenceButtonClick(submoduleId,subModuleName);
    }else{
     
      onClickCallBack(item, index,screens); 
    }   
  };

  const getBase64FromUrl = async (url = 'https://ik.imagekit.io/k38yuwpb2/windows1654795623.jpeg') => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const getScreens = async (submoduleTopicId, actionType) => {
   
    simulationApi
      .getScreens(submoduleTopicId, actionType)
      .then(async (res) => {

        try {
          if (res.ok) {

            const sortedData = res.data;
          
            await Promise.all(
              sortedData.map(async (item, index) => {
                if (item.type === 'SUCCESS' || item.type === 'INTRO') {
                  const base64Img = await getBase64FromUrl(item.backgroundImg);

                  sortedData[index] = {
                    ...item,
                    backgroundBase64: base64Img,
                  };
                }  
          
              })
            );
            
            if(topics[1]!== undefined){
              sortedData.push(topics[1])
            }
            setScreens(sortedData);                       
          }
        } catch (error) {
          console.log('error after data received from getScreens api', error);
         
        }
      })
      .catch((error) => {
        console.log('error getting screens from api ', error);
      
      });
  };
  
  const renderIcon = (item) => {
    if (item.type === 'VIDEO') {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.warning.main}`,
            color: theme.palette.warning.main,
            fontSize: '0.75rem',
          }}
        >
          <Typography variant="caption" component="span" sx={{ color: theme.palette.warning.main }}>
            {'V'}
          </Typography>
        </Box>
      )
    }
    if (item==="SkillSet IQ") {
      return (
        <Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.warning.main}`,
    color: theme.palette.warning.main,
    fontSize: '0.75rem',
  }}
>
  <Typography variant="caption" component="span" sx={{ color: theme.palette.warning.main }}>
    {'Q'}
  </Typography>
</Box>
       
      );
    }
    if (item.actionType?.code === 'REFERENCE') {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.warning.main}`,
            color: theme.palette.warning.main,
            fontSize: '0.75rem',
          }}
        >
          <Typography variant="caption" component="span" sx={{ color: theme.palette.warning.main }}>
            {'R'}
          </Typography>
        </Box>
      )
    }
    if(item.actionType?.code !== 'VIDEO' && item.actionType?.code !== 'REFERENCE' && item !== "SkillSet IQ") {
      
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.warning.main}`,
            color: theme.palette.warning.main,
            fontSize: '0.75rem',
          }}
        >
          <Typography variant="caption" component="span" sx={{ color: theme.palette.warning.main }}>
            {'S'}
          </Typography>
        </Box>
      )

    }
   
  };




  return (
    <>

      {isLoading ? (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: `calc(100vh - 70px)`,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 999,
                }}
              >

                <LottieLoading loading={isLoading} />
              </div>
      ) :(
        <div>
          <List
            sx={{
              width: '100%',
              marginTop: 0,
              bgcolor: 'background.paper',
              paddingTop: '0',
              maxHeight: 'calc(100vh - 322px)',
              overflow: 'auto',
              overflowX: 'hidden',
              scrollbarWidth: 'thin',
              scrollSnapType: 'y proximity',
              scrollBehavior: 'smooth',
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {screens.map((item, index) => { 
              const isSelected =  selectedIndex === index;
              const isCompleted = item.isCompleted;
              return (
                <ListItemButton
                  className={classes.listItem}
                  selected={selectedIndex === 'REFERENCE' ? item.id==='reference':isSelected}
                  onClick={(event) => handleListItemClick(event, index, item)}
                  key={`topic-${index}`}
                  sx={{
                    color: isCompleted ? theme.palette.secondary.main : theme.palette.grey[500],
                    scrollSnapAlign: isSelected ? 'start' : 'none',
                    scrollMargin: isSelected ? '100px' : '0px',
                    '&.Mui-selected': {
                      color: theme.palette.primary.main,
                      backgroundColor: theme.palette.grey[200],
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: theme.palette.secondary.lighter,
                    },
                    '&:hover': {
                      backgroundColor: theme.palette.secondary.lighter,
                    },
                  }}
                >
                  <ListItemIcon>
                    {renderIcon(item)}
                  </ListItemIcon>
                  <ListItemText
                    //  selected={selectedIndex === 'REFERENCE' && item.id==='reference'}
                    primary={item.id==='reference'? 'Reference' : item.title}
                    sx={{
                      color: isSelected ? theme.palette.primary.main : 'inherit',
                    }}
                  />
                </ListItemButton>
              );
            })} 
            
<ListItemButton
              className={classes.listItem}
              // selected={isSelected}
              onClick={() => {
                const path = ['AUTH_USER', 'SUPER_ADMIN', 'CONTENT_WRITER'].includes(userRole) ? 
                  `/app/skilliq-test?subModuleId=${submoduleId}` : 
                  `/auth/skilliq-test?subModuleId=${submoduleId}`;
                navigate(path, { state: { ...location.state } });
              }}
              // key={`topic-${index}`}
              sx={{
                color: theme.palette.grey[500],
                scrollSnapAlign:'start' ,
                scrollMargin:'100px',
                '&.Mui-selected': {
                  color: theme.palette.grey[500],
                  backgroundColor: theme.palette.grey[200],
                },
                '&.Mui-selected:hover': {
                  backgroundColor: theme.palette.secondary.lighter,
                },
                '&:hover': {
                  backgroundColor: theme.palette.secondary.lighter,
                },
              }}
            >
              <ListItemIcon>
                {renderIcon('SkillSet IQ')}
              </ListItemIcon>
              <ListItemText primary={'SkillSet IQ'} />
            </ListItemButton>
          </List>

       
        </div>
      )}
    </>
  );
};

export default VideoTopicList;