import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import DialogModal from '../../../components/modal/DialogModal';

import adminServices from '../../../services/adminServices';


function SubModuleAssessment({ open,  submoduleId, onClose, onSubmit }) {  
  const [loading, setLoading] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState('');

  SubModuleAssessment.propTypes = {
    open: PropTypes.bool,
    submoduleId: PropTypes.number,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func
  };

  useEffect(() => {
    
      setLoading(false)
      setSelectedQuestions('')
    
  }, [open]);
     
  const handleSubmit = async (subModuleId) => {

    console.log('new module...',subModuleId)
   /*  try {
      setLoading(true);
      const result = await adminServices.updateSubModuleParent(from, module, submoduleId);
      if (result.ok) {
          setLoading(false)
          onSubmit();
      } else {
        console.log('Error moving sub module:', result.error);
        onClose()
      }
      
    } catch (error) {
      console.error('Error moving sub module:', error);
    }  */

      onClose()
    
  };

  return (
    <DialogModal open={open} handleClose={onClose} title="Assessment Form for Sub Module">    
    
    
      <form>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Search Questions
        </Typography>
        
          

       
        {selectedQuestions && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <LoadingButton type="submit" onClick={() => handleSubmit(selectedQuestions)} variant="contained" color="primary" fullWidth loading={loading}>
                    Submit
            </LoadingButton>
          </div>
        )}
      </form>
      
    </DialogModal>

  );
}

export default SubModuleAssessment;
